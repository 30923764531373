/* eslint-disable */

import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Card, Col, Row, Skeleton, Breadcrumb, Empty } from 'antd';
import { isEmpty, findIndex } from 'lodash';
import { GROUP_LEADERBOARD_API } from 'Src/alumniGiving/endpoints';
import { getQueryParamValueFromUrl, pollLeaderboardData } from 'Src/alumniGiving/common/utils/helper';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import NavigationFooter from 'Src/common/components/navigationFooter';
import axiosInstance from 'Src/common/utilities/axios_util';
import { formatter } from 'Src/common/utilities/data_util';
import { handleEnterKeyPress } from 'Src/common/components/controller/buttons/buttonController';
import { GIFT_VISIBILITY_TRIBUTE } from 'Src/alumniGiving/constants';
import RelativeProgress from './../relativeProgress';
import TributeModal from './tributeModal';
import './style.scss';
import './../style.scss';
import GiftModal from './giftModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMousePointer, faLeaf, faUsers } from '@fortawesome/pro-light-svg-icons';
import { faCaretRight, faMedal } from '@fortawesome/pro-solid-svg-icons';
import { faHome } from '@fortawesome/pro-duotone-svg-icons';

let pageNumbers = {};
let pageSize = 5;
const initialDelay = 2000;
// removing global url variable and replace it with an id accessible object
const leaderboardUrls = {};
function GroupLeaderboard({ leaderboard }) {
  const TAB_GENEROSITY = 'generosity';
  const TAB_PARTICIPATION = 'participation';

  let tabList = [
    {
      key: TAB_GENEROSITY,
      tab: (
        <div className="giving-v2-card-tab-title">
          <FontAwesomeIcon icon={faLeaf} className="mr8" />
          Generosity
        </div>
      ),
    },
    {
      key: TAB_PARTICIPATION,
      tab: (
        <div className="giving-v2-card-tab-title">
          <FontAwesomeIcon icon={faUsers} className="mr8" />
          Participation
        </div>
      ),
    },
  ];

  const [selectedTab, setSelectedTab] = useState(
    leaderboard.is_generosity_leaderboard_enabled ? TAB_GENEROSITY : TAB_PARTICIPATION,
  );
  const selectedTabRef = useRef(selectedTab);
  const selectedGroupRef = useRef(null);
  const hasDataRef = useRef(null);
  const [settings, dispatch] = SettingsConsumer();
  const [leaderboardData, setLeaderboardData] = useState([]);
  // this will be used to draw relative progress bars
  const [maxVal, setMaxVal] = useState(0);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [loading, setLoading] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [isTributeModalVisible, setIsTributeModalVisible] = useState(false);
  const [isGiftModalVisible, setIsGiftModalVisible] = useState(false);
  const [leaderboardItem, setLeaderboardItem] = useState({});

  function getLeaderboardData(url) {
    return axiosInstance({
      method: 'get',
      url: url,
    });
  }

  function getPollingCondition(currentPage, tab, group) {
    return (
      pageNumbers[leaderboard.id] == currentPage && tab == selectedTabRef.current && group == selectedGroupRef.current
    );
  }

  const fetchLeaderboardData = async (pageUrl, tab) => {
    const currentPage = getQueryParamValueFromUrl(pageUrl, 'page') || 1;
    const group = getQueryParamValueFromUrl(pageUrl, 'group_name') || null;
    if (!getPollingCondition(currentPage, tab, group)) {
      return false;
    }
    if (!hasDataRef.current) setLoading(true);
    try {
      const response = await getLeaderboardData(pageUrl);
      if (getPollingCondition(currentPage, tab, group)) {
        const results = response.data?.results || [];
        setLeaderboardData(results.slice());
        setMaxVal(getMaxValue(results));
        setNext(response.data?.nextPage);
        setPrev(response.data?.currentPage > 1);
        if (response.data) {
          setLoading(false);
          hasDataRef.current = true;
        }
      }
      return response;
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    pageNumbers[leaderboard.id] = 1;
    setLeaderboardData([].slice());
    selectedTabRef.current = selectedTab;
    selectedGroupRef.current = null;
    hasDataRef.current = false;
    leaderboardUrls[leaderboard.id] = getUrlForTab(leaderboard.id);
    pollLeaderboardData(async () => fetchLeaderboardData(leaderboardUrls[leaderboard.id], selectedTab), initialDelay);
    return () => {
      selectedTabRef.current = ''; // Dismounting the poll function when component dismounts
    };
  }, [selectedTab]);

  function getMaxValue(data) {
    if (!data.length) return 0;
    if (selectedTab === TAB_GENEROSITY) {
      return data[0].amount;
    }
    return data[0].count_gifts;
  }

  function onTabChange(key) {
    breadcrumbs.splice(0, breadcrumbs.length);
    setBreadcrumbs(breadcrumbs.slice());
    setSelectedTab(key);
  }

  // function getDescription(item) {
  //     if(selectedTab === TAB_INFLUENCER) {
  //         return <p className="">{`has influenced ${item.count_visits_influenced} visitors, ${item.count_gifts_influenced} gifts and ${item.total_amount_influenced_by_currency && item.total_amount_influenced_by_currency.length && item.total_amount_influenced_by_currency[0].total}`}</p>
  //     }
  //     return <p className="">${item.amount}</p>
  // }

  function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);
      //reverse iteration as may be destructive
      for (var i = pars.length; i-- > 0; ) {
        //idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }
      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
  }
  // passing over url to avoid using global variable
  function generateUrlWithPageNum(url, pageNum) {
    url = removeURLParameter(url, 'page');
    url = url.concat(`&&page=${pageNum}`);
    return url;
  }

  function onNext(id) {
    if (next) {
      // building url for this specific leaderboard
      // using parameter id instead of leaderboard.id because its the same value being passed from the component
      hasDataRef.current = false;
      let url = leaderboardUrls[id];
      const page = ++pageNumbers[id];
      url = generateUrlWithPageNum(url, page);
      pollLeaderboardData(async () => fetchLeaderboardData(url, selectedTab), initialDelay);
    }
  }

  function getUrlForTab(id) {
    // using local url to avoid conflict, returning to store in urls object with id
    let url;
    if (selectedTab === TAB_GENEROSITY) {
      url = GROUP_LEADERBOARD_API.replace('{}', settings.page.id)
        .replace('<lb_id>', id)
        .concat(`?order_by=total_amount&&order=desc`);
    } else {
      url = GROUP_LEADERBOARD_API.replace('{}', settings.page.id)
        .replace('<lb_id>', id)
        .concat(`?order_by=_count&&order=desc`);
    }
    return url;
  }

  function onPrev(id) {
    if (prev) {
      hasDataRef.current = false;
      let url = leaderboardUrls[id];
      const page = --pageNumbers[id];
      url = generateUrlWithPageNum(url, page);
      pollLeaderboardData(async () => fetchLeaderboardData(url, selectedTab), initialDelay);
    }
  }

  function renderAmount(d) {
    return (
      <Row className="flex-wrapper" onClick={() => selectItem(d)}>
        <Col span={12} className="arc-p currency">
          {formatter(settings.page.currency_display).format(d.amount)}
        </Col>
        <Col span={11} className="full-width">
          <RelativeProgress
            highestValue={maxVal}
            value={d.amount}
            strokeColor={primaryColor}
            strokeWidth={12}></RelativeProgress>
        </Col>
      </Row>
    );
  }

  function renderGiftCount(d) {
    return (
      <Row className="flex-wrapper" onClick={() => selectItem(d)}>
        <Col span={12} className="arc-p currency">
          {new Intl.NumberFormat('en-US').format(d.count_gifts)}
        </Col>
        <Col span={11} className="full-width">
          <RelativeProgress
            highestValue={maxVal}
            value={d.count_gifts}
            strokeColor={primaryColor}
            strokeWidth={12}></RelativeProgress>
        </Col>
      </Row>
    );
  }

  function getGroupData(d) {
    if (!d.groupable) {
      selectItem(d);
      return;
    }
    pageNumbers[leaderboard.id] = 1;
    leaderboardUrls[leaderboard.id] = getUrlForTab(leaderboard.id);
    leaderboardUrls[leaderboard.id] = leaderboardUrls[leaderboard.id].concat(
      `&&group_name=${encodeURIComponent(d.label)}`,
    );
    selectedGroupRef.current = encodeURIComponent(d.label);
    hasDataRef.current = false;
    pageNumbers[leaderboard.id] = 1;
    pollLeaderboardData(async () => fetchLeaderboardData(leaderboardUrls[leaderboard.id], selectedTab), initialDelay);
    breadcrumbs.push(d.label);
    setBreadcrumbs(breadcrumbs);
  }

  function goToSpecificBreadcrumb(group, index) {
    if (index === breadcrumbs.length - 1) {
      return;
    }
    leaderboardUrls[leaderboard.id] = getUrlForTab(leaderboard.id);
    if (index !== -1) {
      leaderboardUrls[leaderboard.id] = leaderboardUrls[leaderboard.id].concat(
        `&&group_name=${encodeURIComponent(group)}`,
      );
    }
    selectedGroupRef.current = index === -1 ? null : encodeURIComponent(group);
    pageNumbers[leaderboard.id] = 1;
    breadcrumbs.splice(index + 1, breadcrumbs.length);
    setBreadcrumbs(breadcrumbs);
    hasDataRef.current = false;
    pollLeaderboardData(async () => fetchLeaderboardData(leaderboardUrls[leaderboard.id], selectedTab), initialDelay);
  }

  function selectItem(d) {
    setLeaderboardItem(d);
    if (leaderboard.gift_visibility === GIFT_VISIBILITY_TRIBUTE) {
      toggleTributeModal();
    } else {
      toggleGiftModal();
    }
  }

  function toggleGiftModal() {
    setIsGiftModalVisible(!isGiftModalVisible);
  }

  function toggleTributeModal() {
    setIsTributeModalVisible(!isTributeModalVisible);
  }

  function getTabs() {
    if (!leaderboard.is_generosity_leaderboard_enabled) {
      tabList.splice(
        findIndex(tabList, (val) => val.key === TAB_GENEROSITY),
        1,
      );
    }
    if (!leaderboard.is_participation_leaderboard_enabled) {
      tabList.splice(
        findIndex(tabList, (val) => val.key === TAB_PARTICIPATION),
        1,
      );
    }
    return tabList;
  }

  function findAndSelectItem(label) {
    selectItem({ groupable: true, label });
  }

  const getActions = () => {
    if (isEmpty(leaderboardData)) {
      return <></>;
    }
    return (
      <NavigationFooter onNext={onNext} onPrev={onPrev} prevDisabled={!prev} nextDisabled={!next} id={leaderboard.id} />
    );
  };

  return (
    <div>
      <div className="section-title">{leaderboard.name}</div>
      <Card
        bordered={false}
        className="leaderboard-card campaign-details-block arc-card-box-shadow arc-card-border-radius group-leaderboard"
        tabList={getTabs()}
        activeTabKey={selectedTab}
        onTabChange={onTabChange}
        actions={[getActions()]}>
        {isEmpty(breadcrumbs) && !isEmpty(leaderboardData) && (
          <p className="arc-support support-text">
            <FontAwesomeIcon icon={faMousePointer} className="mr8" />
            Click or tap below to view more details
          </p>
        )}
        {!isEmpty(breadcrumbs) && (
          <Fragment>
            <Breadcrumb>
              {
                <Fragment>
                  <Breadcrumb.Item className="active" onClick={() => goToSpecificBreadcrumb('home', -1)}>
                    <FontAwesomeIcon icon={faHome} />
                  </Breadcrumb.Item>
                  {breadcrumbs.map((crumb, index) => {
                    return (
                      <Breadcrumb.Item
                        className={index === breadcrumbs.length - 1 ? 'disabled' : 'active'}
                        onClick={() => goToSpecificBreadcrumb(crumb, index)}>
                        {crumb}
                      </Breadcrumb.Item>
                    );
                  })}
                </Fragment>
              }
            </Breadcrumb>
            <FontAwesomeIcon
              icon={faMedal}
              className="arc-cursor-pointer arc-color-primary medal-icon"
              onClick={() => findAndSelectItem(breadcrumbs[breadcrumbs.length - 1])}
            />
          </Fragment>
        )}
        <Choose>
          <When condition={loading}>
            <Card bordered={false} className="leaderboard-loading-card">
              <Skeleton active />
              <Skeleton active />
            </Card>
          </When>
          <When condition={isEmpty(leaderboardData)}>
            <div className="empty-wrapper">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </When>
          <Otherwise>
            {leaderboardData?.map((d, index) => (
              <Row type="flex" align="middle" justify="space-between" className="group-influencer-row">
                <Col span={12}>
                  <p
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => handleEnterKeyPress(e, () => getGroupData(d))}
                    className={`arc-p arc-color-primary name ${d.groupable ? 'groupable' : ''}`}
                    onClick={() => getGroupData(d)}>
                    {pageSize * (pageNumbers[leaderboard.id] - 1) + (index + 1)}. &nbsp;{d.label}
                    <If condition={d.groupable}>
                      <FontAwesomeIcon icon={faCaretRight} className="ml8 groupable-icon" />
                    </If>
                  </p>
                </Col>
                <Col span={12} className="arc-text-align-r">
                  {selectedTab === TAB_GENEROSITY ? renderAmount(d) : renderGiftCount(d)}
                </Col>
              </Row>
            ))}
          </Otherwise>
        </Choose>
        {isTributeModalVisible && (
          <TributeModal leaderboard={leaderboard} item={leaderboardItem} closeModal={toggleTributeModal} />
        )}
        {isGiftModalVisible && (
          <GiftModal leaderboard={leaderboard} item={leaderboardItem} closeModal={toggleGiftModal} />
        )}
      </Card>
    </div>
  );
}

export default GroupLeaderboard;

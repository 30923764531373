import React from 'react';
import { string, func } from 'prop-types';
import { Row, Col, Button, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { faFacebookSquare, faSquareXTwitter, faLinkedin, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';
import ThemeXModal from 'Src/common/components/themeXModal';

function SocialMediaShareModal({ url, title, toggleShareModal, toggleInfluencerModel }) {
  return (
    <ThemeXModal
      visible
      closable
      maskClosable
      keyboard={false}
      handleCancel={() => toggleShareModal(false)}
      modalTitle=""
      Footer={
        <If condition={!window.blockInfluencerEmails}>
          <Button className="influencer-action-btn" type="primary" onClick={toggleInfluencerModel} size="large">
            Get my link
          </Button>
        </If>
      }
      wrapClassName="giving-page-share-modal">
      <div className="header-image-wrapper">
        <img alt="" src={`${window.staticPath}img/givingx/giving_page_share.svg`} />
      </div>

      <Row className="avatar-row">
        <Col span={24} className="avatar-col">
          <p className="title arc-H200 arc-color-black">Share on social media</p>
        </Col>
      </Row>
      <Row className="social-medial-icons-row">
        <FacebookShareButton title={title} url={url}>
          <FontAwesomeIcon icon={faFacebookSquare} />
        </FacebookShareButton>
        <TwitterShareButton title={title} url={url}>
          <FontAwesomeIcon icon={faSquareXTwitter} />
        </TwitterShareButton>
        <LinkedinShareButton title={title} url={url}>
          <FontAwesomeIcon icon={faLinkedin} />
        </LinkedinShareButton>
        <EmailShareButton title={title} url={url}>
          <FontAwesomeIcon icon={faEnvelope} />
        </EmailShareButton>
        <WhatsappShareButton title={title} url={url}>
          <FontAwesomeIcon icon={faWhatsappSquare} />
        </WhatsappShareButton>
        <If condition={!window.blockInfluencerEmails}>
          <Divider className="mt32 mb24">OR</Divider>
          <p className="arc-H200 arc-text-align-c mb32">
            Get your unique link to track your impact and participate in the leaderboard
          </p>
        </If>
      </Row>
    </ThemeXModal>
  );
}

SocialMediaShareModal.defaultProps = {
  title: '',
};

SocialMediaShareModal.propTypes = {
  url: string.isRequired,
  title: string,
  toggleShareModal: func.isRequired,
  toggleInfluencerModel: func.isRequired,
};

export default SocialMediaShareModal;

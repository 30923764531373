import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

const maxLines = 3;
const transitionDuration = 300; // Duration in milliseconds

function PageInfo() {
  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [actualHeight, setActualHeight] = useState(0);
  const [collapsedHeight, setCollapsedHeight] = useState(`${maxLines * 1.57}em`);
  const [applyClamp, setApplyClamp] = useState(true); // Controls when line clamp is applied
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const lineHeight = parseFloat(window.getComputedStyle(contentRef.current).lineHeight);
      const maxHeight = lineHeight * maxLines;

      setCollapsedHeight(`${maxHeight}px`);
      setActualHeight(contentRef.current.scrollHeight);

      if (contentRef.current.scrollHeight > maxHeight) {
        setShowButton(true);
      }
    }
  }, []);

  const toggleExpanded = () => {
    if (!expanded) {
      // Expanding
      setApplyClamp(false); // Remove line-clamp immediately
      setExpanded(true);
      setTimeout(() => setActualHeight(contentRef.current.scrollHeight), 10); // Update height dynamically
    } else {
      // Collapsing
      setActualHeight(contentRef.current.scrollHeight); // Ensure correct height before collapse
      setTimeout(() => {
        setExpanded(false);
        setTimeout(() => setApplyClamp(true), transitionDuration); // Apply clamp after animation
      }, 10);
    }
  };

  return (
    <div className="page-info">
      <div className="page-info-content">
        <h1 className="arc-H250 arc-color-black arc-font-weight-medium-imp mb12">{window.pageData.name}</h1>

        <div
          ref={contentRef}
          style={{
            maxHeight: expanded ? `${actualHeight}px` : collapsedHeight,
            overflow: 'hidden',
            position: 'relative',
            transition: `max-height ${transitionDuration}ms ease`,
            whiteSpace: 'pre-wrap',
            ...(applyClamp
              ? {
                  display: '-webkit-box',
                  WebkitLineClamp: maxLines,
                  WebkitBoxOrient: 'vertical',
                }
              : {}), // Remove line-clamp while animating
          }}
          className="arc-p"
          dangerouslySetInnerHTML={{ __html: window.pageData.description }}
        />

        {showButton && (
          <Button type="link" className="link-btn arc-link-btn mt12" onClick={toggleExpanded}>
            <span className="arc-text-underline arc-color-primary">{expanded ? 'View Less' : 'View More'}</span>
          </Button>
        )}
      </div>
    </div>
  );
}

export default PageInfo;

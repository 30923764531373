import React, { Fragment, useEffect } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Tooltip } from 'antd';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FORM_FIELD_FULL_NAME, FORM_FIELD_SPLIT_NAME } from 'Src/common/constants';
import { getKeyValue } from 'Src/common/utilities/data_util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '@ant-design/compatible/assets/index.css';
import MDEditor from "@uiw/react-md-editor";

/**
 * Name Input Component
 */
function NameInput({ form, formInput, formRecord, renderHelpTextWithIcon }) {
  useEffect(() => {
    if (getNameType() === FORM_FIELD_SPLIT_NAME) {
      const fullName = formRecord[formInput.id];
      if (fullName) {
        const splitName = fullName.split(' ');
        const firstName = splitName.slice(0, splitName.length - 1).join(' ');
        const lastName = splitName[splitName.length - 1];

        form.setFieldsValue({
          first_name: firstName,
          last_name: lastName,
          [formInput.key]: { first_name: firstName, last_name: lastName },
        });
      }
    }
  }, []);

  function getNameType() {
    return formInput.options[0].value;
  }

  const { getFieldDecorator } = form;

  function getAriaLabel(formItem) {
    let ariaLabel = getKeyValue(formItem, 'label');
    const rules = getKeyValue(formItem, 'rules');
    if (rules?.required?.value) {
      ariaLabel += ' Mandatory Field';
    }
    return ariaLabel;
  }

  function renderFormItemLabel(formItem) {
    const tooltipText = getKeyValue(formItem, 'tooltip_text');
    if (tooltipText) {
      return (
        <span>
          <span style={{ marginRight: '0.5rem' }}>{formItem.label}</span>
          <Tooltip title={tooltipText}>
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      );
    }
    return formItem.label;
  }

    function onFirstNameChange(e) {
        const first_name = e.target.value;
    const last_name = form.getFieldValue('last_name');
    form.setFieldsValue({ [formInput.key]: { first_name, last_name } });
  }

  function onLastNameChange(e) {
    const first_name = form.getFieldValue('first_name');
    const last_name = e.target.value;
    form.setFieldsValue({ [formInput.key]: { first_name, last_name } });
  }

  function validateFullName(rule, value, callback) {
    value = value && value.trim();
    if (value) {
      const regex =
        /^[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*( [a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*)+$/;
      if (!regex.test(value.toLowerCase())) {
        callback('Please enter full name');
      }
    }
    callback();
  }

  return (
    <Choose>
      <When condition={getNameType() === FORM_FIELD_FULL_NAME}>
        <Form.Item
          label={renderFormItemLabel(formInput)}
          aria-label={getAriaLabel(formInput)}
          extra={renderHelpTextWithIcon(getKeyValue(formInput, 'help_text'))}
          colon={false}>
          {getFieldDecorator(formInput.key, {
            rules: [
              {
                required: getKeyValue(formInput, 'rules__required__value', false),
                message: getKeyValue(formInput, 'rules__required__message', 'Please enter value'),
              },
              {
                validator: validateFullName,
              },
            ],
            // validateTrigger: 'onBlur'
          })(
            <Input
              size={getKeyValue(formInput, 'input_size', 'default')}
              placeholder={getKeyValue(formInput, 'placeholder')}
              disabled={getKeyValue(formInput, 'disabled', false)}
              aria-label={getAriaLabel(formInput)}
            />,
          )}
        </Form.Item>
      </When>
      <Otherwise>
        <Form.Item>
          {getFieldDecorator(formInput.key)(
            <Fragment>
              <Form.Item
                label="First Name"
                aria-label="First Name"
                extra={renderHelpTextWithIcon(getKeyValue(formInput, 'help_text'))}
                colon={false}>
                {getFieldDecorator('first_name', {
                  initialValue: formRecord?.custom_data?.first_name,
                  rules: [
                    {
                      required: getKeyValue(formInput, 'rules__required__value', false),
                      message: getKeyValue(formInput, 'rules__required__message', 'Please enter first name'),
                    },
                  ],
                })(
                  <Input
                    size={getKeyValue(formInput, 'input_size', 'default')}
                    placeholder={getKeyValue(formInput, 'placeholder')}
                    disabled={getKeyValue(formInput, 'disabled', false)}
                    aria-label={getAriaLabel(formInput)}
                    onChange={onFirstNameChange}
                  />,
                )}
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                className="last-name-form-item"
                label="Last Name"
                aria-label="Last Name"
                extra={renderHelpTextWithIcon(getKeyValue(formInput, 'help_text'))}
                colon={false}>
                {getFieldDecorator('last_name', {
                  initialValue: formRecord?.custom_data?.last_name,
                  rules: [
                    {
                      required: getKeyValue(formInput, 'rules__required__value', false),
                      message: getKeyValue(formInput, 'rules__required__message', 'Please enter last name'),
                    },
                  ],
                })(
                  <Input
                    size={getKeyValue(formInput, 'input_size', 'default')}
                    placeholder={getKeyValue(formInput, 'placeholder')}
                    disabled={getKeyValue(formInput, 'disabled', false)}
                    aria-label={getAriaLabel(formInput)}
                    onChange={onLastNameChange}
                  />,
                )}
              </Form.Item>
            </Fragment>,
          )}
        </Form.Item>
      </Otherwise>
    </Choose>
  );
}

export default NameInput;

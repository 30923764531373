export default {
  v: '5.9.0',
  fr: 60,
  ip: 0,
  op: 241,
  w: 609,
  h: 812,
  nm: 'lottie (desktop) 1',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'cannon (small - left)',
      fr: 60,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: '_small-side',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [218, 320, 0], ix: 2, l: 2 },
            a: { a: 0, k: [400, 400, 0], ix: 1, l: 2 },
            s: { a: 0, k: [-100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 800,
          h: 800,
          ip: 15,
          op: 234,
          st: 15,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      nm: '_small-side',
      fr: 60,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'circle a',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 1, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 29, s: [321.019] },
                { t: 158, s: [1800] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 1,
                  s: [599.5, 838, 0],
                  to: [-30, -106.667, 0],
                  ti: [46.667, 0, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 29,
                  s: [419.5, 198, 0],
                  to: [-46.667, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 158, s: [319.5, 838, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0, 0] },
                  t: 1,
                  s: [50, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 29,
                  s: [100, 100, 100],
                },
                { t: 128, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0.776499986649, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 1,
          op: 159,
          st: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'circle b',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 3, s: [0] },
                { t: 128, s: [1440] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 3,
                  s: [599.5, 838, 0],
                  to: [-26.667, -93.333, 0],
                  ti: [66.667, 0, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 21,
                  s: [439.5, 278, 0],
                  to: [-66.667, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 128, s: [199.5, 838, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0, 0] },
                  t: 3,
                  s: [50, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 21,
                  s: [100, 100, 100],
                },
                { t: 98, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.325300008059, 0.119999997318, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3,
          op: 129,
          st: 3,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'star a',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 1, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 31, s: [343.949] },
                { t: 158, s: [1800] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 1,
                  s: [596.087, 836.292, 0],
                  to: [-36.098, -100, 0],
                  ti: [52.765, 0, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 31,
                  s: [379.5, 236.292, 0],
                  to: [-52.765, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 158, s: [279.5, 836.292, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0, 0] },
                  t: 1,
                  s: [50, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 31,
                  s: [100, 100, 100],
                },
                { t: 128, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'sr',
                  sy: 1,
                  d: 1,
                  pt: { a: 0, k: 5, ix: 3 },
                  p: { a: 0, k: [0, 0], ix: 4 },
                  r: { a: 0, k: 0, ix: 5 },
                  ir: { a: 0, k: 5, ix: 6 },
                  is: { a: 0, k: 0, ix: 8 },
                  or: { a: 0, k: 12, ix: 7 },
                  os: { a: 0, k: 0, ix: 9 },
                  ix: 1,
                  nm: 'Polystar Path 1',
                  mn: 'ADBE Vector Shape - Star',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.235300004482, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Polystar 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 1,
          op: 159,
          st: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'star b',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 3, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 23, s: [288] },
                { t: 128, s: [1800] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 3,
                  s: [596.087, 836.292, 0],
                  to: [-39.431, -113.333, 0],
                  ti: [66.098, 1.667, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 23,
                  s: [359.5, 156.292, 0],
                  to: [-66.098, -1.667, 0],
                  ti: [0, 0, 0],
                },
                { t: 128, s: [199.5, 826.292, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0, 0] },
                  t: 3,
                  s: [50, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 23,
                  s: [100, 100, 100],
                },
                { t: 98, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'sr',
                  sy: 1,
                  d: 1,
                  pt: { a: 0, k: 5, ix: 3 },
                  p: { a: 0, k: [0, 0], ix: 4 },
                  r: { a: 0, k: 0, ix: 5 },
                  ir: { a: 0, k: 5, ix: 6 },
                  is: { a: 0, k: 0, ix: 8 },
                  or: { a: 0, k: 12, ix: 7 },
                  os: { a: 0, k: 0, ix: 9 },
                  ix: 1,
                  nm: 'Polystar Path 1',
                  mn: 'ADBE Vector Shape - Star',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.313699990511, 0.756900012493, 0.329400002956, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Polystar 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3,
          op: 129,
          st: 3,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'rec a',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 33, s: [381.468] },
                { t: 218, s: [2520] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 0,
                  s: [599.5, 842, 0],
                  to: [-23.333, -100, 0],
                  ti: [41.333, 1.333, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 33,
                  s: [459.5, 242, 0],
                  to: [-41.333, -1.333, 0],
                  ti: [0, 0, 0],
                },
                { t: 218, s: [351.5, 834, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0, 0] },
                  t: 0,
                  s: [50, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 33,
                  s: [100, 100, 100],
                },
                { t: 188, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0.776499986649, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 219,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'rec b',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 2, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 25, s: [262.857] },
                { t: 191, s: [2160] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 2,
                  s: [599.5, 842, 0],
                  to: [-23.333, -113.333, 0],
                  ti: [74, 5, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 25,
                  s: [459.5, 162, 0],
                  to: [-74, -5, 0],
                  ti: [0, 0, 0],
                },
                { t: 191, s: [155.5, 812, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0, 0] },
                  t: 2,
                  s: [50, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 25,
                  s: [100, 100, 100],
                },
                { t: 161, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.325300008059, 0.119999997318, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 192,
          st: 2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'square a',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 35, s: [462.385] },
                { t: 218, s: [2880] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 0,
                  s: [599.5, 838, 0],
                  to: [-43.333, -123.333, 0],
                  ti: [60, 0, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 35,
                  s: [339.5, 98, 0],
                  to: [-60, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 218, s: [239.5, 838, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0, 0] },
                  t: 0,
                  s: [50, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 35,
                  s: [100, 100, 100],
                },
                { t: 188, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.235300004482, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 219,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'square b',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 2, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 27, s: [285.714] },
                { t: 191, s: [2160] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 2,
                  s: [599.5, 838, 0],
                  to: [-30, -120, 0],
                  ti: [75, 3.333, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 27,
                  s: [419.5, 118, 0],
                  to: [-75, -3.333, 0],
                  ti: [0, 0, 0],
                },
                { t: 191, s: [149.5, 818, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0, 0] },
                  t: 2,
                  s: [50, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 27,
                  s: [100, 100, 100],
                },
                { t: 161, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.325300008059, 0.119999997318, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 192,
          st: 2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'circle a 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 1, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 29, s: [-371.368] },
                { t: 191, s: [-2520] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 1,
                  s: [599.5, 838, 0],
                  to: [-53.333, -113.333, 0],
                  ti: [56.667, -3.333, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 29,
                  s: [279.5, 158, 0],
                  to: [-56.667, 3.333, 0],
                  ti: [0, 0, 0],
                },
                { t: 191, s: [259.5, 858, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0.167, 0] },
                  t: 1,
                  s: [100, 50, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 29,
                  s: [100, 100, 100],
                },
                { t: 161, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0.776499986649, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 1,
          op: 192,
          st: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'circle b 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 3, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 21, s: [-250.839] },
                { t: 158, s: [-2160] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 3,
                  s: [599.5, 838, 0],
                  to: [-53.333, -100, 0],
                  ti: [63, -7, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 21,
                  s: [279.5, 238, 0],
                  to: [-63, 7, 0],
                  ti: [0, 0, 0],
                },
                { t: 158, s: [221.5, 880, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0.167, 0] },
                  t: 3,
                  s: [100, 50, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 21,
                  s: [100, 100, 100],
                },
                { t: 128, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.313699990511, 0.756900012493, 0.329400002956, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3,
          op: 159,
          st: 3,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'star a 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 1, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 31, s: [-397.895] },
                { t: 191, s: [-2520] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 1,
                  s: [596.087, 836.292, 0],
                  to: [-9.431, -113.333, 0],
                  ti: [42.765, -3.333, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 31,
                  s: [539.5, 156.292, 0],
                  to: [-42.765, 3.333, 0],
                  ti: [0, 0, 0],
                },
                { t: 191, s: [339.5, 856.292, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0.167, 0] },
                  t: 1,
                  s: [100, 50, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 31,
                  s: [100, 100, 100],
                },
                { t: 161, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'sr',
                  sy: 1,
                  d: 1,
                  pt: { a: 0, k: 5, ix: 3 },
                  p: { a: 0, k: [0, 0], ix: 4 },
                  r: { a: 0, k: 0, ix: 5 },
                  ir: { a: 0, k: 5, ix: 6 },
                  is: { a: 0, k: 0, ix: 8 },
                  or: { a: 0, k: 12, ix: 7 },
                  os: { a: 0, k: 0, ix: 9 },
                  ix: 1,
                  nm: 'Polystar Path 1',
                  mn: 'ADBE Vector Shape - Star',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.235300004482, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Polystar 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 1,
          op: 192,
          st: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'star b 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 3, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 23, s: [-278.71] },
                { t: 158, s: [-2160] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 3,
                  s: [596.087, 836.292, 0],
                  to: [-12.765, -96.667, 0],
                  ti: [52.765, -3.333, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 23,
                  s: [519.5, 256.292, 0],
                  to: [-52.765, 3.333, 0],
                  ti: [0, 0, 0],
                },
                { t: 158, s: [279.5, 856.292, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0.167, 0] },
                  t: 3,
                  s: [100, 50, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 23,
                  s: [100, 100, 100],
                },
                { t: 128, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'sr',
                  sy: 1,
                  d: 1,
                  pt: { a: 0, k: 5, ix: 3 },
                  p: { a: 0, k: [0, 0], ix: 4 },
                  r: { a: 0, k: 0, ix: 5 },
                  ir: { a: 0, k: 5, ix: 6 },
                  is: { a: 0, k: 0, ix: 8 },
                  or: { a: 0, k: 12, ix: 7 },
                  os: { a: 0, k: 0, ix: 9 },
                  ix: 1,
                  nm: 'Polystar Path 1',
                  mn: 'ADBE Vector Shape - Star',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.325300008059, 0.119999997318, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Polystar 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3,
          op: 159,
          st: 3,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'rec a 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 33, s: [-556.875] },
                { t: 128, s: [-2160] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 0,
                  s: [599.5, 842, 0],
                  to: [-16.667, -120, 0],
                  ti: [66.667, 0, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 33,
                  s: [499.5, 122, 0],
                  to: [-66.667, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 128, s: [199.5, 842, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0.167, 0] },
                  t: 0,
                  s: [100, 50, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 33,
                  s: [100, 100, 100],
                },
                { t: 98, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.235300004482, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 129,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'rec b 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 2, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 25, s: [-306.667] },
                { t: 218, s: [-2880] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 2,
                  s: [599.5, 842, 0],
                  to: [-22.62, -109.87, 0],
                  ti: [2.877, -2.055, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 25,
                  s: [339.5, 262, 0],
                  to: [-51.333, 36.667, 0],
                  ti: [0, 0, 0],
                },
                { t: 218, s: [219.5, 862, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0.167, 0] },
                  t: 2,
                  s: [100, 50, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 25,
                  s: [100, 100, 100],
                },
                { t: 188, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0.776499986649, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 219,
          st: 2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'square a 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 35, s: [-590.625] },
                { t: 128, s: [-2160] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 0,
                  s: [599.5, 838, 0],
                  to: [-20, -90, 0],
                  ti: [36.667, 0, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 35,
                  s: [479.5, 298, 0],
                  to: [-36.667, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 128, s: [379.5, 838, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0.167, 0] },
                  t: 0,
                  s: [100, 50, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 35,
                  s: [100, 100, 100],
                },
                { t: 98, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0.776499986649, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 129,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'square b 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 2, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 27, s: [-333.333] },
                { t: 218, s: [-2880] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 2,
                  s: [599.5, 838, 0],
                  to: [-10, -103.333, 0],
                  ti: [50, 0, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 27,
                  s: [539.5, 218, 0],
                  to: [-50, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 218, s: [299.5, 838, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0.167, 0] },
                  t: 2,
                  s: [100, 50, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 27,
                  s: [100, 100, 100],
                },
                { t: 188, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.325300008059, 0.119999997318, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 219,
          st: 2,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      nm: 'cannon (small - right)',
      fr: 60,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: '_small-side',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [260, 320, 0], ix: 2, l: 2 },
            a: { a: 0, k: [400, 400, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 800,
          h: 800,
          ip: 0,
          op: 219,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      nm: 'cannon (small - top)',
      fr: 60,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'left',
          refId: 'comp_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [400, 400, 0], ix: 2, l: 2 },
            a: { a: 0, k: [400, 400, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 800,
          h: 800,
          ip: 13,
          op: 313,
          st: 13,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'right',
          refId: 'comp_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [400, 400, 0], ix: 2, l: 2 },
            a: { a: 0, k: [400, 400, 0], ix: 1, l: 2 },
            s: { a: 0, k: [-100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 800,
          h: 800,
          ip: 30,
          op: 330,
          st: 30,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      nm: 'right',
      fr: 60,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'circle a 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 8, s: [0] },
                { t: 155, s: [1800] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 8,
                  s: [325.643, -26.292, 0],
                  to: [-101.333, 75.667, 0],
                  ti: [15.333, -507.667, 0],
                },
                { t: 155, s: [125.643, 835.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 8,
                  s: [50, 100, 100],
                },
                { t: 155, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0.776499986649, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8,
          op: 156,
          st: -7,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'circle b 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 2, s: [0] },
                { t: 215, s: [2520] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 2,
                  s: [619.5, -26.292, 0],
                  to: [-138, 77.667, 0],
                  ti: [-2, -497.667, 0],
                },
                { t: 215, s: [259.5, 835.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 2,
                  s: [50, 100, 100],
                },
                { t: 215, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.325300008059, 0.119999997318, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 216,
          st: -13,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'star a 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 6, s: [0] },
                { t: 245, s: [2880] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 6,
                  s: [376.929, -28, 0],
                  to: [-213.333, 157.667, 0],
                  ti: [173.333, -127.667, 0],
                },
                { t: 245, s: [116.929, 834, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 6,
                  s: [50, 100, 100],
                },
                { t: 245, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'sr',
                  sy: 1,
                  d: 1,
                  pt: { a: 0, k: 5, ix: 3 },
                  p: { a: 0, k: [0, 0], ix: 4 },
                  r: { a: 0, k: 0, ix: 5 },
                  ir: { a: 0, k: 5, ix: 6 },
                  is: { a: 0, k: 0, ix: 8 },
                  or: { a: 0, k: 12, ix: 7 },
                  os: { a: 0, k: 0, ix: 9 },
                  ix: 1,
                  nm: 'Polystar Path 1',
                  mn: 'ADBE Vector Shape - Star',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.235300004482, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Polystar 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 246,
          st: -9,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'star b 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 2, s: [0] },
                { t: 125, s: [1800] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.999 },
                  o: { x: 0.167, y: 0.001 },
                  t: 2,
                  s: [171.786, -28, 0],
                  to: [0, 0, 0],
                  ti: [-161.333, -275.667, 0],
                },
                { t: 125, s: [251.786, 834, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 2,
                  s: [50, 100, 100],
                },
                { t: 125, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'sr',
                  sy: 1,
                  d: 1,
                  pt: { a: 0, k: 5, ix: 3 },
                  p: { a: 0, k: [0, 0], ix: 4 },
                  r: { a: 0, k: 0, ix: 5 },
                  ir: { a: 0, k: 5, ix: 6 },
                  is: { a: 0, k: 0, ix: 8 },
                  or: { a: 0, k: 12, ix: 7 },
                  os: { a: 0, k: 0, ix: 9 },
                  ix: 1,
                  nm: 'Polystar Path 1',
                  mn: 'ADBE Vector Shape - Star',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.313699990511, 0.756900012493, 0.329400002956, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Polystar 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 126,
          st: -13,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'rec a 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 4, s: [0] },
                { t: 185, s: [2520] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 4,
                  s: [428.214, -22.292, 0],
                  to: [-167.333, 119.667, 0],
                  ti: [-130.667, -315.667, 0],
                },
                { t: 185, s: [228.214, 839.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 4,
                  s: [50, 100, 100],
                },
                { t: 185, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0.776499986649, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4,
          op: 186,
          st: -11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'rec b 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 6, s: [0] },
                { t: 245, s: [2880] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 6,
                  s: [223.071, -22.292, 0],
                  to: [0, 0, 0],
                  ti: [-92.571, -383.708, 0],
                },
                { t: 245, s: [223.071, 839.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 6,
                  s: [50, 100, 100],
                },
                { t: 245, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.325300008059, 0.119999997318, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 246,
          st: -9,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'square a 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 4, s: [0] },
                { t: 217, s: [2520] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.999 },
                  o: { x: 0.167, y: 0.001 },
                  t: 4,
                  s: [120.5, -26.292, 0],
                  to: [13, 430.305, 0],
                  ti: [52.221, -418.892, 0],
                },
                { t: 217, s: [198.5, 833.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 4,
                  s: [50, 100, 100],
                },
                { t: 217, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.235300004482, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4,
          op: 218,
          st: -11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'square b 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 8, s: [0] },
                { t: 215, s: [2520] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 8,
                  s: [274.357, -26.292, 0],
                  to: [157.333, 415.667, 0],
                  ti: [22.667, -253.667, 0],
                },
                { t: 215, s: [114.357, 835.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 8,
                  s: [50, 100, 100],
                },
                { t: 215, s: [100, 50, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.235300004482, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8,
          op: 216,
          st: -7,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'circle a 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 2, s: [0] },
                { t: 125, s: [-1800] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 2,
                  s: [365.643, -26.292, 0],
                  to: [-101.333, 75.667, 0],
                  ti: [15.333, -507.667, 0],
                },
                { t: 125, s: [165.643, 835.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 2,
                  s: [100, 50, 100],
                },
                { t: 125, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0.776499986649, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 126,
          st: -13,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'circle b 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 8, s: [0] },
                { t: 245, s: [-2880] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 8,
                  s: [519.5, -26.292, 0],
                  to: [-138, 77.667, 0],
                  ti: [-2, -497.667, 0],
                },
                { t: 245, s: [159.5, 835.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 8,
                  s: [100, 50, 100],
                },
                { t: 245, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.313699990511, 0.756900012493, 0.329400002956, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8,
          op: 246,
          st: -7,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'star a 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 6, s: [0] },
                { t: 245, s: [-2880] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 6,
                  s: [416.929, -28, 0],
                  to: [-213.333, 157.667, 0],
                  ti: [173.333, -127.667, 0],
                },
                { t: 245, s: [156.929, 834, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 6,
                  s: [100, 50, 100],
                },
                { t: 245, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'sr',
                  sy: 1,
                  d: 1,
                  pt: { a: 0, k: 5, ix: 3 },
                  p: { a: 0, k: [0, 0], ix: 4 },
                  r: { a: 0, k: 0, ix: 5 },
                  ir: { a: 0, k: 5, ix: 6 },
                  is: { a: 0, k: 0, ix: 8 },
                  or: { a: 0, k: 12, ix: 7 },
                  os: { a: 0, k: 0, ix: 9 },
                  ix: 1,
                  nm: 'Polystar Path 1',
                  mn: 'ADBE Vector Shape - Star',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.235300004482, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Polystar 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 246,
          st: -9,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'star b 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 8, s: [0] },
                { t: 155, s: [-2160] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.999 },
                  o: { x: 0.167, y: 0.001 },
                  t: 8,
                  s: [211.786, -28, 0],
                  to: [0, 0, 0],
                  ti: [-161.333, -275.667, 0],
                },
                { t: 155, s: [291.786, 834, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 8,
                  s: [100, 50, 100],
                },
                { t: 155, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'sr',
                  sy: 1,
                  d: 1,
                  pt: { a: 0, k: 5, ix: 3 },
                  p: { a: 0, k: [0, 0], ix: 4 },
                  r: { a: 0, k: 0, ix: 5 },
                  ir: { a: 0, k: 5, ix: 6 },
                  is: { a: 0, k: 0, ix: 8 },
                  or: { a: 0, k: 12, ix: 7 },
                  os: { a: 0, k: 0, ix: 9 },
                  ix: 1,
                  nm: 'Polystar Path 1',
                  mn: 'ADBE Vector Shape - Star',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.325300008059, 0.119999997318, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Polystar 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8,
          op: 156,
          st: -7,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'rec a 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 4, s: [0] },
                { t: 185, s: [-2160] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 4,
                  s: [468.214, -22.292, 0],
                  to: [-167.333, 119.667, 0],
                  ti: [-130.667, -315.667, 0],
                },
                { t: 185, s: [268.214, 839.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 4,
                  s: [100, 50, 100],
                },
                { t: 185, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.235300004482, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4,
          op: 186,
          st: -11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'rec b 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 6, s: [0] },
                { t: 245, s: [-2880] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 6,
                  s: [263.071, -22.292, 0],
                  to: [0, 143.667, 0],
                  ti: [-114.571, -267.708, 0],
                },
                { t: 245, s: [263.071, 839.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 6,
                  s: [100, 50, 100],
                },
                { t: 245, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0.776499986649, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 246,
          st: -9,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'square a 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 2, s: [0] },
                { t: 217, s: [-2520] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.999 },
                  o: { x: 0.167, y: 0.001 },
                  t: 2,
                  s: [160.5, -26.292, 0],
                  to: [13, 430.305, 0],
                  ti: [52.221, -418.892, 0],
                },
                { t: 217, s: [238.5, 833.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 2,
                  s: [100, 50, 100],
                },
                { t: 217, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0.776499986649, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 218,
          st: -13,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'square b 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 50, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 4, s: [0] },
                { t: 185, s: [-2160] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 4,
                  s: [314.357, -26.292, 0],
                  to: [157.333, 415.667, 0],
                  ti: [22.667, -253.667, 0],
                },
                { t: 185, s: [154.357, 835.708, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 4,
                  s: [100, 50, 100],
                },
                { t: 185, s: [50, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.325300008059, 0.119999997318, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4,
          op: 186,
          st: -11,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'cannon (small - left)',
      refId: 'comp_0',
      sr: 0.80000001192093,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [76, 452, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 360, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 480,
      h: 720,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'cannon (small - right)',
      refId: 'comp_2',
      sr: 0.80000001192093,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [533, 452, 0], ix: 2, l: 2 },
        a: { a: 0, k: [479.994, 360, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 480,
      h: 720,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: 'cannon (small - top)',
      refId: 'comp_3',
      sr: 0.80000001192093,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [304, 408, 0], ix: 2, l: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1, l: 2 },
        s: { a: 0, k: [102, 102, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

import React, { useState, useEffect } from 'react';
import { Col, Row, Progress, Statistic } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faGift, faSeedling } from '@fortawesome/pro-light-svg-icons';
import CurrencyRenderer from 'Src/common/components/currencyRenderer';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

import './style.scss';

function CampaignGoalBlock({
  completionPercentage,
  goal,
  goalPrefix,
  currency,
  countDonors,
  countGifts,
  amount,
  isMatchingGiftChallenge,
  showProgressAnimation = false,
  isNewLayout = false,
}) {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (showProgressAnimation) {
      let start = null;

      const animate = (timestamp) => {
        if (!start) start = timestamp;
        const progress = Math.min(((timestamp - start) / 1000) * 100, completionPercentage);
        setPercent(progress);

        if (progress < 100) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    }
  }, [completionPercentage]);

  return (
    <div className="goal-block-wrapper">
      <Choose>
        <When condition={isNewLayout}>
          <div className="arc-color-black arc-H150 mb8 mt16">
            <Statistic value={parseFloat(completionPercentage)} />%{' '}
            {isMatchingGiftChallenge ? 'Unlocked of' : 'Reached of'}
            {'  '}
            <If condition={goalPrefix === 'amount'}>
              <CurrencyRenderer currencyId={currency} />
            </If>
            <Statistic value={goal} /> <If condition={goalPrefix === 'gift'}>{goal > 1 ? 'gifts ' : 'gift '}</If>
            {isMatchingGiftChallenge ? 'gift match' : 'goal'}
          </div>
        </When>
        <Otherwise>
          <div className="arc-color-black goal-completion-percentage">
            <Statistic value={parseFloat(completionPercentage)} />%
          </div>
          <div className="arc-p arc-color-black goal-giving-of">
            {isMatchingGiftChallenge ? 'Unlocked of' : 'Reached of'}
            {'  '}
            <If condition={goalPrefix === 'amount'}>
              <CurrencyRenderer currencyId={currency} />
            </If>
            <Statistic value={goal} /> <If condition={goalPrefix === 'gift'}>{goal > 1 ? 'gifts ' : 'gift '}</If>
            {isMatchingGiftChallenge ? 'gift match' : 'goal'}
          </div>
        </Otherwise>
      </Choose>
      <Row type="flex" justify="space-between" gutter={32}>
        <Col>
          <div className="arc-p arc-color-B85">
            <Choose>
              <When condition={goalPrefix === 'donor'}>
                <FontAwesomeIcon icon={faUsers} />
                <I18nCustomFormatter id="donors" values={{ donors: countDonors }} />
              </When>
              <Otherwise>
                <FontAwesomeIcon icon={faGift} className="mr4" />
                <I18nCustomFormatter id="gifts" values={{ gifts: countGifts }} />
              </Otherwise>
            </Choose>
          </div>
        </Col>
        <Col>
          <div className="arc-p arc-color-B85">
            <FontAwesomeIcon icon={faSeedling} className="mr8" />
            <CurrencyRenderer currencyId={currency} />
            <Statistic value={parseFloat(amount)} />{' '}
            <Choose>
              <When condition={isMatchingGiftChallenge}>
                <I18nCustomFormatter id="amount-matched" />
              </When>
              <Otherwise>
                <I18nCustomFormatter id="amount-raised" />
              </Otherwise>
            </Choose>
          </div>
        </Col>
      </Row>
      <div className="goal-progressbar">
        <Progress
          percent={showProgressAnimation ? percent : completionPercentage}
          className={showProgressAnimation && completionPercentage >= 100 && 'progress-shimmer'}
        />
      </div>
    </div>
  );
}

export default CampaignGoalBlock;

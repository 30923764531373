import { isEmpty } from 'lodash';
import { CURRENCIES } from 'Src/common/constants';
import { countDecimals, toFixed } from 'Src/common/utilities/data_util';

/**
 * Handles enter key press
 */
export function handleEnterKeyPress(event, func) {
  if (event.key === 'Enter') {
    func();
  }
}

export function getDonationType(gift) {
  if (gift?.subscription_plan?.interval) return gift?.subscription_plan?.interval;
  const { payment_configs: paymentConfigs } = gift.account;
  if (!isEmpty(paymentConfigs)) {
    const firstPaymentConfig = paymentConfigs[0];
    if (firstPaymentConfig && firstPaymentConfig.interval) {
      return firstPaymentConfig.interval;
    }
  }
  return null;
}

export const getSequenceClassNames = (index, totalL) => {
  index += 1;
  if (index === 1) {
    return 'first';
  }
  if (index === totalL) {
    return 'last';
  }
  return 'middle';
};

export const checkAmount = (amount) => {
  if (Number.isNaN(parseFloat(amount))) {
    return undefined;
  }
  if (countDecimals(amount) > 2) return toFixed(parseFloat(amount), 2);
  return amount;
};

export const getSymbolFromCurrencyID = (currency) => {
  const matchingCurrency = CURRENCIES.find((cur) => cur.id === currency);
  const htmlEntity = matchingCurrency?.html_entity || '';

  if (!htmlEntity) return '';

  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlEntity;

  return tempElement.textContent || tempElement.innerText || '';
};

import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import './style.scss';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

const getSeconds = (diff) => Math.trunc(diff) % 60;

const getMintues = (diff) => Math.trunc(diff / 60) % 60;

const getHours = (diff) => Math.trunc(diff / 3600) % 24;

const getDays = (diff) => Math.trunc(diff / 86400);

const makeItAlteastTwoDigits = (value) => (value.toString().length <= 1 ? `0${value.toString()}` : value.toString());

const showDays = (days) => {
  if (days) {
    return (
      <li>
        <p className="digit">{makeItAlteastTwoDigits(days)}</p>
        <p className="text">
          <I18nCustomFormatter
            id="days-str-only-label"
            values={{
              days,
            }}
          />
        </p>
      </li>
    );
  }
  return null;
};

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id = null;
    function tick() {
      if (!savedCallback.current()) {
        clearInterval(id);
      }
    }
    if (delay !== null) {
      id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const getDateTimeDiff = (dateTime) => {
  const diff = moment.parseZone(dateTime).unix() - moment.parseZone().unix();
  return diff > 0 ? diff : 0;
};

function CountDown({ dateTime, callback }) {
  const [diff, setDiff] = useState(getDateTimeDiff(dateTime));
  useInterval(() => {
    if (diff === 0) {
      callback && callback();
      return 0;
    }
    setDiff(diff - 1);
    return 1;
  }, 1000);

  return (
    <div className="count-down">
      <ul className="count-down-ul" role="presentation">
        {showDays(getDays(diff))}
        <li className="count-down-li">
          <p className="digit">{makeItAlteastTwoDigits(getHours(diff))}</p>
          <p className="text">
            <I18nCustomFormatter
              id="hrs-str-only-label"
              values={{
                hrs: getHours(diff),
              }}
            />
          </p>
        </li>
        <li className="count-down-li">
          <p className="digit">{makeItAlteastTwoDigits(getMintues(diff))}</p>
          <p className="text">
            <I18nCustomFormatter
              id="mins-str-only-label"
              values={{
                mins: getMintues(diff),
              }}
            />
          </p>
        </li>
        <li className="count-down-li">
          <p className="digit">{makeItAlteastTwoDigits(getSeconds(diff))}</p>
          <p className="text">Sec</p>
        </li>
      </ul>
    </div>
  );
}

export default CountDown;

import React from 'react';
import './style.scss';

function MetricCard({
  icon,
  title,
  subtitle,
  iconBackgroundColor,
  iconColor,
  isDarkMode = false,
  isNewLayout = false,
  isDateTimeMetric = false,
}) {
  return (
    <Choose>
      <When condition={isNewLayout}>
        <div className="campaign-metric-card-pill" style={{ backgroundColor: iconBackgroundColor }}>
          <div className="icon">{icon}</div>
          <p className="title arc-support">
            <Choose>
              <When condition={isDateTimeMetric}>
                {title} {subtitle}
              </When>
              <Otherwise>{title}</Otherwise>
            </Choose>
          </p>
        </div>
      </When>
      <Otherwise>
        <div className="campaign-metric-card">
          <div
            className="icon"
            style={{
              backgroundColor: iconBackgroundColor,
              color: iconColor,
            }}>
            {icon}
          </div>
          <div className="content arc-d-flex arc-flex-direction-column ml8">
            <p
              className={`title arc-support arc-font-weight-bold arc-text-uppercase ${
                isDarkMode ? 'arc-color-white' : 'arc-color-gray11'
              }`}>
              {title}
            </p>
            <p className={`subtitle arc-support ${isDarkMode ? 'arc-color-gray5' : 'arc-color-gray8'}`}>{subtitle}</p>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
}

export default MetricCard;

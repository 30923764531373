import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Card, Col, Affix, Button } from 'antd';
import { capitalize, find, debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import LottieLoader from 'Src/common/components/lottieLoader';
import {
  DESCRIPTION_SECTION,
  CHALLENGE_SECTION,
  CAMPAIGN_HUB_SECTION,
  TRIBUTE_SECTION,
  LEADERBOARD_SECTION,
} from 'Src/alumniGiving/constants';
import { handleEnterKeyPress } from 'Src/common/components/controller/buttons/buttonController';
import axiosInstance from 'Src/common/utilities/axios_util';
import { SettingsConsumer } from '../../context/settings';
import ChallengesCard from '../challengesCard';
import { LEADERBOARD_API_PATH } from '../../endpoints';
import HonorWall from '../honorWall';
import InfluencerLeaderboard from '../leaderboards/influencerLeaderboard';
import GroupLeaderboard from '../leaderboards/groupLeaderboard';
import CampaignHubs from '../campaignHubs';

import './style.scss';

function Section({ type, sectionId, settings, leaderboards, hubs, setIsTributeEmpty, setIsChallengeEmpty }) {
  switch (type) {
    case 'description': {
      if (!settings?.page?.description) return null;
      return (
        <Col span={24}>
          <Card bordered={false} className="campaign-details-block arc-card-box-shadow arc-card-border-radius fr-view">
            <div className="page-description" dangerouslySetInnerHTML={{ __html: settings.page.description }} />
          </Card>
        </Col>
      );
    }
    case 'tribute': {
      if (!settings.page.is_gift_tribute_enabled) return null;
      return <HonorWall setIsTributeEmpty={setIsTributeEmpty} />;
    }
    case 'challenges': {
      return <ChallengesCard setIsChallengeEmpty={setIsChallengeEmpty} />;
    }
    case 'leaderboard': {
      const leaderboard = find(leaderboards, ({ id }) => id === Number(sectionId));
      if (
        !leaderboard ||
        !(leaderboard.is_generosity_leaderboard_enabled || leaderboard.is_participation_leaderboard_enabled)
      ) {
        return null;
      }
      return (
        <Col span={24}>
          <Choose>
            <When condition={leaderboard?.type === 'default'}>
              <InfluencerLeaderboard leaderboard={leaderboard} />
            </When>
            <Otherwise>
              <GroupLeaderboard leaderboard={leaderboard} />
            </Otherwise>
          </Choose>
        </Col>
      );
    }
    case 'campaignHub': {
      const hub = find(hubs, ({ id }) => id === Number(sectionId));
      if (!hub) return null;
      return (
        <Col span={24}>
          <CampaignHubs CampaignHubData={hub} isNewLayout />
        </Col>
      );
    }
    default:
      return null;
  }
}

function AfterCampaignStartedNew({ hubs, loadedHubs }) {
  const [isChallengeEmpty, setIsChallengeEmpty] = useState(false);
  const [isTributeEmpty, setIsTributeEmpty] = useState(false);
  const [settings] = SettingsConsumer();
  const [{ loadedLeaderBoard, leaderboards }, setLeaderboardState] = useState({
    loadedLeaderBoard: false,
    leaderboards: [],
  });
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const [scrollArrows, setScrollArrows] = useState({ left: false, right: false });

  const sectionRefs = useRef({});
  const navRef = useRef(null);

  useEffect(() => {
    const fetchLeaderboards = async () => {
      try {
        const response = await axiosInstance.get(LEADERBOARD_API_PATH.replace('{}', settings.page.id));
        setLeaderboardState({
          leaderboards: response.data.count ? response.data.results : [],
          loadedLeaderBoard: true,
        });
      } catch (error) {
        setLeaderboardState((prev) => ({ ...prev, loadedLeaderBoard: true }));
      }
    };

    fetchLeaderboards();
  }, []);

  const checkScrollButtons = debounce(() => {
    if (navRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = navRef.current;
      setScrollArrows({
        left: scrollLeft > 0,
        right: scrollLeft < scrollWidth - clientWidth,
      });
    }
  }, 300);

  useEffect(() => {
    checkScrollButtons();
  }, [leaderboards, hubs]);

  const scrollNav = (direction) => {
    if (navRef.current) {
      navRef.current.scrollBy({
        left: direction * 200,
        behavior: 'smooth',
      });
      checkScrollButtons();
    }
  };

  const ensureNavItemVisible = useCallback((sectionKey) => {
    setTimeout(() => {
      if (navRef.current && sectionKey) {
        const navItem = navRef.current.querySelector(`[data-section-key="${sectionKey}"]`);
        if (navItem) {
          const navContainer = navRef.current;
          const itemRect = navItem.getBoundingClientRect();
          const containerRect = navContainer.getBoundingClientRect();

          if (itemRect.left < containerRect.left || itemRect.right > containerRect.right) {
            const scrollLeft = navItem.offsetLeft - containerRect.width / 2 + itemRect.width / 2;
            navContainer.scrollTo({
              left: scrollLeft,
              behavior: 'smooth',
            });
          }
        }
      }
    }, 0);
  }, []);

  const scrollToSection = useCallback((sectionKey) => {
    const sectionRef = sectionRefs.current[sectionKey];
    if (sectionRef) {
      const elementPosition = sectionRef.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - 90,
        behavior: 'smooth',
      });
      setSelectedNavItem(sectionKey);
      ensureNavItemVisible();
    }
  }, []);

  const sections = useMemo(() => {
    const result = [];
    const sortedSections = [...(settings?.page?.sections || [])].sort(
      (a, b) => Number(a.display_order) - Number(b.display_order),
    );

    sortedSections.forEach(({ section }) => {
      switch (section.section_type) {
        case DESCRIPTION_SECTION:
          result.push({
            sectionType: 'description',
            sectionId: '',
          });
          break;
        case CHALLENGE_SECTION:
          result.push({
            sectionType: 'challenges',
            sectionId: '',
          });
          break;
        case TRIBUTE_SECTION:
          if (settings.page.is_gift_tribute_enabled) {
            result.push({
              sectionType: 'tribute',
              sectionId: '',
            });
          }
          break;
        case CAMPAIGN_HUB_SECTION:
          result.push({
            sectionType: 'campaignHub',
            sectionId: section.section_id,
          });
          break;
        case LEADERBOARD_SECTION:
          result.push({
            sectionType: 'leaderboard',
            sectionId: section.section_id,
          });
          break;
        default:
          break;
      }
    });

    return result;
  }, []);

  const renderSection = useCallback(
    ({ sectionType, sectionId }) => {
      const sectionKey = sectionType + sectionId;
      return (
        <div
          ref={(el) => {
            sectionRefs.current[sectionKey] = el;
          }}
          key={sectionKey}
          className="mt32 nav-content">
          <Section
            type={sectionType}
            sectionId={sectionId}
            settings={settings}
            leaderboards={leaderboards}
            hubs={hubs}
            setIsChallengeEmpty={setIsChallengeEmpty}
            setIsTributeEmpty={setIsTributeEmpty}
          />
        </div>
      );
    },
    [settings, leaderboards, hubs],
  );

  const renderNavItems = useMemo(
    () =>
      sections
        .map((section) => {
          const sectionKey = `${section.sectionType}${section.sectionId}`;
          let name = capitalize(section.sectionType);

          switch (section.sectionType) {
            case 'campaignHub': {
              const hub = hubs.find(({ id }) => id === Number(section.sectionId));
              if (!hub) return null;
              name = hub.name;
              break;
            }

            case 'leaderboard': {
              const leaderboard = leaderboards.find(({ id }) => id === Number(section.sectionId));
              if (
                !leaderboard ||
                !(leaderboard.is_generosity_leaderboard_enabled || leaderboard.is_participation_leaderboard_enabled)
              ) {
                return null;
              }
              name = leaderboard.name;
              break;
            }

            case 'challenges':
              if (isChallengeEmpty) return null;
              break;

            case 'tribute':
              if (isTributeEmpty) return null;
              break;

            default:
              break;
          }

          return { sectionKey, name };
        })
        .filter(Boolean)
        .map(({ sectionKey, name }) => (
          <div
            key={sectionKey}
            tabIndex={0}
            role="button"
            data-section-key={sectionKey}
            onKeyDown={(e) => handleEnterKeyPress(e, () => scrollToSection(sectionKey))}
            onClick={() => scrollToSection(sectionKey)}
            className={sectionKey === selectedNavItem ? 'selected arc-H150 nav-item' : 'nav-item arc-p'}>
            {name}
          </div>
        )),
    [sections, hubs, leaderboards, selectedNavItem, isChallengeEmpty, isTributeEmpty, scrollToSection],
  );

  if (!loadedHubs || !loadedLeaderBoard) {
    return (
      <div className="sections-loader">
        <LottieLoader />
      </div>
    );
  }

  return (
    <div className="after-campaign-wrapper">
      <Affix>
        <div className="nav-container">
          <If condition={scrollArrows.left}>
            <Button className="scroll-btn left-btn ant-btn link-btn" type="link" onClick={() => scrollNav(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </If>
          <div className="nav-items-wrapper" ref={navRef} onScroll={checkScrollButtons}>
            {renderNavItems}
          </div>
          <If condition={scrollArrows.right}>
            <Button className="scroll-btn right-btn ant-btn link-btn" type="link" onClick={() => scrollNav(1)}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </If>
        </div>
      </Affix>
      {sections.map(renderSection)}
    </div>
  );
}

export default AfterCampaignStartedNew;

import React, { Component, Fragment } from 'react';
import { string, node, func, bool } from 'prop-types';
import { Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import './style.scss';

class ThemeXModal extends Component {
  handleModalCancel(e) {
    if (this.props.handleCancel) {
      return this.props.handleCancel(e);
    }
  }

  render() {
    const { modalTitle, graphic, Description, Footer } = this.props;

    return (
      <Modal
        width={this.props.width}
        title={modalTitle && <div className="arc-H200">{modalTitle}</div>}
        footer={null}
        visible={this.props.visible}
        maskClosable={this.props.maskClosable}
        closable={this.props.closable}
        keyboard={this.props.keyboard}
        onCancel={(e) => this.handleModalCancel(e)}
        className="themex-modal"
        wrapClassName={this.props.wrapClassName}
        closeIcon={
          <div>
            <FontAwesomeIcon style={{ fontSize: '20px' }} icon={faTimes} />
          </div>
        }>
        <If condition={this.props.header}>
          <div className="themex-modal-header-content">{this.props.header}</div>
        </If>
        <If condition={graphic}>
          <div className="themex-modal-graphic-container">
            <img src={graphic} alt="" className="themex-modal-graphic" />
          </div>
        </If>
        <If condition={this.props.title || Description}>
          <div className="themex-modal-adjacent-graphic-content">
            <h2 className="arc-H300 arc-B85 themex-modal-title" tabIndex={-1}>
              {this.props.title}
            </h2>
            {Description}
          </div>
        </If>
        <div className="theme-modal-children-content">{this.props.children}</div>
        <div className="theme-modal-footer">{Footer}</div>
      </Modal>
    );
  }
}

ThemeXModal.propTypes = {
  width: string,
  modalTitle: node,
  visible: bool,
  maskClosable: bool,
  closable: bool,
  graphic: string,
  wrapClassName: string,
  title: string,
  Description: node,
  Footer: node,
  footerFixedButtonText: string,
  footerFixedButtonLoading: bool,
  footerFixedButtonClickHandler: func,
  handleCancel: func,
};

ThemeXModal.defaultProps = {
  width: '24rem',
  visible: false,
  maskClosable: true,
  closable: true,
  graphic: null,
  title: '',
  wrapClassName: '',
  footerFixedButtonText: '',
  footerFixedButtonLoading: false,
};

export default ThemeXModal;

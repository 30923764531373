import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Row, Col, Tooltip } from 'antd';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { find } from 'lodash';
import ImageWithFallbackSrc from 'Src/common/components/imageWithFallbackSrc';
import { getRandomCoverImage } from 'Src/common/utilities/dom_utils';
import DivButton from 'Src/common/components/controller/buttons/buttonController';
import { announceToScreenReader } from 'Src/common/utilities/a11y_util';
import { checkAmount, getSymbolFromCurrencyID } from 'Src/alumniGiving/helper';
import CurrencyRenderer from '../../../../common/components/currencyRenderer';
import { GiftConsumer } from '../../../context/gift';
import { SettingsConsumer } from '../../../context/settings';
import { success } from '../../../actionCreators';
import { MODULE_GIFT } from '../../../constants';

const maxLines = 2;

function FundCard({
  name,
  description,
  cover_photo: coverPhoto,
  quickSlabs,
  id,
  isLocked,
  tabIndex,
  setShowAddAnotherGift,
}) {
  const [gift, giftDispatch] = GiftConsumer();
  const [settings] = SettingsConsumer();
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [fundAmount, setFundAmount] = useState();
  const [isUserInputHidden, setIsUserInputHidden] = useState(
    settings.page.is_fund_category_enabled && fundDoesNotHaveAmount(),
  );
  const contentRef = useRef(null);

  useEffect(() => {
    const currentFund = find(gift.funds, ({ fund }) => fund.id === id);
    if (currentFund) {
      setFundAmount(currentFund.fund.amount || undefined);
    } else {
      setFundAmount();
    }
    if (gift?.account.currency) {
      const symbol = getSymbolFromCurrencyID(gift.account.currency);
      setCurrencySymbol(symbol);
    }
  }, [gift]);

  const inputSectionAnimation = useSpring({
    from: {
      height: 0,
      opacity: 0,
    },
    to: {
      height: !isUserInputHidden ? contentRef.current?.scrollHeight : 0,
      opacity: !isUserInputHidden ? 1 : 0,
    },
    config: { tension: 200, friction: 20 },
  });

  function fundDoesNotHaveAmount() {
    const currentFund = find(gift.funds, ({ fund }) => fund.id === id);
    return !currentFund?.fund.amount;
  }

  const updateFundAmount = (amount) => {
    if (isLocked) return;
    if (parseFloat(amount) < 0) return;

    // NOTE: To be fixed later for firefox (to prevent entering non-numeric values)
    // if (e && !amount.match(/^[0-9]+$/)) {
    //   return;
    // }

    for (let i = 0; i < gift.funds.length; i++) {
      // If multiple attribution is not enabled, only allow to select single amount
      if (!settings.page.is_multiple_funds_attribution_enabled && gift.funds[i].fund.id !== id) {
        gift.funds[i].fund.amount = 0;
        gift.funds[i].fund.is_open = false;
      }
      if (gift.funds[i].fund.id === id) {
        if (setShowAddAnotherGift && !gift.funds[i].fund.amount && parseFloat(amount) > 0) {
          setTimeout(() => {
            setShowAddAnotherGift(true);
          }, 1000);
        }
        gift.funds[i].fund.amount = checkAmount(amount);
        gift.funds[i].fund.is_open = false;
      }
    }
    setFundAmount(checkAmount(amount));
    giftDispatch(success(MODULE_GIFT, gift));

    announceToScreenReader(`Fund amount updated to ${currencySymbol}${amount}`);
  };

  return (
    <div className="fund-card-wrapper" key={id}>
      <div className={`fund-card-detail ${settings.page.is_fund_category_enabled ? 'fund-card-detail-reverse' : ''}`}>
        <Choose>
          <When condition={coverPhoto}>
            <ImageWithFallbackSrc alt="fund-image" className="fund-img" src={coverPhoto} width={60} height={60} />
          </When>
          <When condition={!settings.page.is_fund_category_enabled}>
            <ImageWithFallbackSrc
              alt="fund-image"
              className="fund-img"
              src={`${window.staticPath}img/givingx/no-image-placeholder.svg`}
              fallbackSrc={getRandomCoverImage()}
            />
          </When>
        </Choose>
        <div className="fund-card-details-wrapper">
          <div className="fund-card-text-info">
            <div className="fund-card-title arc-color-B65 arc-H150 arc-font-weight-Medium ">{name || ''}</div>
            <div
              className={`fund-card-description arc-color-B65 ${
                settings.page.is_fund_category_enabled ? 'arc-support fund-card-description-expandable mt4' : 'arc-p'
              }`}
              // Styles for description text
              style={{
                maxHeight: !isUserInputHidden ? 'none' : `${maxLines * 1.57}em`,
                display: !isUserInputHidden ? 'block' : '-webkit-box',
                WebkitLineClamp: maxLines,
              }}>
              {description || ''}
            </div>
          </div>
          <If condition={isUserInputHidden}>
            <Button
              type="link"
              className="add-to-cart-btn arc-color-primary-imp arc-btn-link link-btn"
              onClick={() => setIsUserInputHidden(false)}>
              <FontAwesomeIcon icon={faPlus} className="mr8" /> Add to cart
            </Button>
          </If>
        </div>
      </div>
      <animated.div style={inputSectionAnimation}>
        <div ref={contentRef}>
          <div className="fund-input">
            <Input
              id={`${name}-input`}
              aria-label={`Enter amount for fund: ${name}`}
              onWheel={(e) => e.target.blur()}
              type="number"
              readOnly={isLocked}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(isLocked && {
                suffix: (
                  <Tooltip
                    title={
                      <p className="arc-support">
                        You cannot edit this amount. It has been locked by the administrator. For any assistance, please
                        contact {window.collegeEmail}.
                      </p>
                    }>
                    <FontAwesomeIcon icon={faLock} className="arc-color-B25" />
                  </Tooltip>
                ),
              })}
              tabIndex={tabIndex}
              value={fundAmount}
              onChange={(e) => updateFundAmount(e.target.value, e)}
              prefix={<CurrencyRenderer currencyId={gift.account.currency} />}
              placeholder="Enter Amount"
              suffix={
                <If condition={fundAmount}>
                  <button
                    aria-label="Clear input"
                    className="custom-clear-button"
                    tabIndex={tabIndex}
                    type="button"
                    onClick={() => updateFundAmount('')}>
                    <FontAwesomeIcon icon={faTimesCircle} className="switch-to-new-reports-icon" />
                  </button>
                </If>
              }
            />
          </div>
          <If condition={!isLocked && settings.page.are_quick_select_options_visible}>
            <Row gutter={[6, 8]} className="fund-select">
              {quickSlabs.map(
                (slab, idx) =>
                  idx <= 5 && (
                    <Col key={slab} span={8}>
                      <DivButton
                        onClick={() => updateFundAmount(slab)}
                        className={`fund-select-item arc-H100 arc-font-weight-bold arc-cursor-p ${
                          parseFloat(fundAmount) === parseFloat(slab) && 'selected'
                        }`}
                        ariaLabel={
                          parseFloat(fundAmount) === parseFloat(slab) ? `${currencySymbol}${fundAmount} selected` : ''
                        }>
                        {' '}
                        <CurrencyRenderer currencyId={gift.account.currency} />
                        {slab}
                      </DivButton>
                    </Col>
                  ),
              )}
            </Row>
          </If>
        </div>
      </animated.div>
    </div>
  );
}

export default FundCard;

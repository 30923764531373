import { message, Tooltip, Input, Button, Col, Drawer, Menu, Row, Statistic } from 'antd';
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from 'react';
import {
  faChevronDown,
  faChevronUp,
  faPlusCircle,
  faLock,
  faClose,
  faHandHoldingSeedling,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';
import { useTransition, animated } from 'react-spring';
import CurrencyRenderer from 'Src/common/components/currencyRenderer';
import { handleEnterKeyPress } from 'Src/common/components/controller/buttons/buttonController';
import { GiftConsumer } from 'Src/alumniGiving/context/gift';
import { getDonationType, getSequenceClassNames, checkAmount, getSymbolFromCurrencyID } from 'Src/alumniGiving/helper';
import { success } from 'Src/alumniGiving/actionCreators';
import {
  MODULE_GIFT,
  INTERVAL_NAME_MAPPING,
  PAYMENT_INTERVAL_NAME_MAPPING,
  TYPE_OTHER_FUND,
} from 'Src/alumniGiving/constants';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
import { announceToScreenReader } from 'Src/common/utilities/a11y_util';
import './style.scss';

function Cart({ proceedToNextStep, goBack, intl }) {
  const [settings] = SettingsConsumer();
  const [gift, giftDispatch] = GiftConsumer();
  const [showLimitMenu, setShowLimitMenu] = useState(false);
  const [donationType, setDonationType] = useState(getDonationType(gift));
  const [limit, setLimit] = useState(gift?.subscription_plan?.limit || null);
  const [intervals, setIntervals] = useState([]);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const giftRef = useRef(gift);
  const currencySymbol = getSymbolFromCurrencyID(gift.account.currency);

  useEffect(() => {
    const { payment_configs: paymentConfigs } = gift.account;
    if (!isEmpty(paymentConfigs)) {
      setIntervals(paymentConfigs.map((config) => config.interval));
    }

    if (gift.funds) {
      // mark whatever funds that have amount as dirty, so that if they get their amount changed to 0, they will not be removed from cart
      gift.funds.forEach((orderedFund) => {
        if (orderedFund.fund.amount) {
          orderedFund.fund.is_dirty = true;
        }
      });
    }

    giftDispatch(success(MODULE_GIFT, gift));

    return () => {
      const giftL = giftRef.current;
      // Remove the dirty flag all funds
      if (giftL.funds) {
        giftL.funds.forEach((orderedFund) => {
          orderedFund.fund.is_dirty = false;
        });
      }

      giftDispatch(success(MODULE_GIFT, giftL));
    };
  }, []);

  useEffect(() => {
    giftRef.current = gift;
  }, [gift]);

  const total = getAmountSumForFunds();

  function getAmountSumForFunds() {
    let subtotal = 0;
    gift.funds.forEach((orderedFund) => {
      if (orderedFund.fund.amount) {
        subtotal += parseFloat(orderedFund.fund.amount);
      }
    });
    return Math.round((subtotal + Number.EPSILON) * 100) / 100;
  }

  const getPaymentConfigByInterval = (interval) => {
    const { payment_configs: paymentConfigs } = gift.account || {};
    return find(paymentConfigs, (config) => config.interval === interval);
  };

  const getSupportedLimits = () => {
    const reqPaymentConfig = getPaymentConfigByInterval(donationType);
    const { supported_limits: supportedLimits } = reqPaymentConfig || {};
    return supportedLimits;
  };

  const shouldShowLimit = () => {
    const { is_pledge_limit_enabled: isPledgeLimitEnabled } = getPaymentConfigByInterval(donationType) || {
      is_pledge_limit_enabled: false,
    };
    return isPledgeLimitEnabled;
  };

  const toggleLimitMenuVisibility = () => {
    setShowLimitMenu(!showLimitMenu);
  };

  const handleLimitChange = (key) => {
    if (key === 'no_limit') {
      setLimit(null);
    } else {
      setLimit(key);
    }
    setShowLimitMenu(false);
  };

  const calculateTabIndex = (isPopup = false) => {
    if (showLimitMenu) {
      return isPopup ? 0 : -1;
    }
    return 0;
  };

  const deleteFundFromCart = (id) => {
    for (let i = 0; i < gift.funds.length; i++) {
      if (gift.funds[i].fund.id === id) {
        gift.funds[i].fund.amount = 0;
        gift.funds[i].fund.is_open = false;
        gift.funds[i].fund.is_dirty = false;
      }
    }
    giftDispatch(success(MODULE_GIFT, gift));
    setTimeout(() => {
      setIsCartEmpty(gift.funds.every((fund) => !fund.fund.amount && !fund.is_dirty));
    }, 300);
  };

  const onChangeDonationType = (value) => {
    setDonationType(value);
    const firstPaymentConfig = getPaymentConfigByInterval(value);
    setLimit(firstPaymentConfig.default_limit);
    gift.donation_type = value;

    if (settings.page.is_fund_category_enabled) {
      message.info(
        `Gifts in your cart are set to ${intl.formatMessage({
          id: PAYMENT_INTERVAL_NAME_MAPPING[value],
        })}`,
      );
    }

    for (let i = 0; i < gift.funds.length; i++) {
      if (!gift.funds[i].fund.isLocked) {
        gift.funds[i].fund.amount = null;
        if (gift.funds[i].fund.type === TYPE_OTHER_FUND) gift.funds[i].fund.name = '';
        gift.funds[i].is_open = false;
      }
    }
    giftDispatch(success(MODULE_GIFT, gift));
  };

  const updateFundAmount = (id, amount, isLocked) => {
    if (isLocked) return;
    if (parseFloat(amount) < 0) return;

    for (let i = 0; i < gift.funds.length; i++) {
      if (gift.funds[i].fund.id === id) {
        gift.funds[i].fund.amount = checkAmount(amount);
        gift.funds[i].fund.is_open = false;
      }
    }
    giftDispatch(success(MODULE_GIFT, gift));

    announceToScreenReader(`Fund amount updated to ${currencySymbol}${amount}`);
  };

  function validate() {
    let minimumDonationAmount = null;
    const reqPaymentConfig = getPaymentConfigByInterval(donationType);
    minimumDonationAmount = reqPaymentConfig?.minimum_donation_amount;

    if (minimumDonationAmount && total < minimumDonationAmount) {
      message.error(`Minimum donation amount is ${minimumDonationAmount}`);
      return;
    }

    gift.donation_type = donationType === null ? 'one_time' : 'recurring';
    gift.subscription_plan = {
      interval: donationType,
      limit,
    };
    giftDispatch(success(MODULE_GIFT, gift));

    proceedToNextStep();
  }

  function LimitOptions({ limitOption }) {
    const supportedLimits = getSupportedLimits();
    const activeLimit = limitOption?.toString() || 'no_limit';
    return (
      <div>
        <p className="menu-heading arc-H150 arc-color-black">Select schedule</p>
        <Menu
          selectable
          className="limit-options-menu"
          selectedKeys={[activeLimit]}
          onClick={({ key }) => handleLimitChange(key)}>
          <Menu.Item
            onKeyDown={(e) => handleEnterKeyPress(e, () => handleLimitChange('no_limit'))}
            tabIndex={calculateTabIndex(true)}
            key="no_limit">
            <CurrencyRenderer currencyId={gift.account.currency} />
            <Statistic value={parseFloat(total).toFixed(2)} />
            {`/${donationType === 'month' ? 'mo' : 'yr'} (${
              donationType === 'month' ? 'monthly' : 'yearly'
            } supporter)`}
          </Menu.Item>
          <If condition={!isEmpty(supportedLimits)}>
            {supportedLimits.map((limitL) => (
              <Menu.Item
                tabIndex={calculateTabIndex(true)}
                key={limitL}
                onKeyDown={(e) => handleEnterKeyPress(e, () => handleLimitChange(limitL))}>
                <CurrencyRenderer currencyId={gift.account.currency} />
                <Statistic value={parseFloat(total * limitL).toFixed(2)} />
                &nbsp;(
                {limitL}
                {donationType === 'month' ? 'mo' : 'yr'})
              </Menu.Item>
            ))}
          </If>
        </Menu>
      </div>
    );
  }

  const fundSlabsComponent = (
    <div className="fund-slabs-header mt20 mb12">
      <Row gutter={window.innerWidth <= 500 ? 0 : 1} className="payment-type-select">
        {intervals.map((interval, id) => (
          <Col
            key={`${interval}`}
            span={24 / intervals.length}
            onKeyDown={(e) => handleEnterKeyPress(e, () => onChangeDonationType(interval))}
            onClick={() => onChangeDonationType(interval)}>
            <div
              aria-label={`${intl.formatMessage({
                id: 'how-would-you-like-to-give',
              })} ${intl.formatMessage({ id: PAYMENT_INTERVAL_NAME_MAPPING[interval] })} ${
                donationType === interval ? 'Selected' : ''
              }`}
              role="button"
              className={`select-item ${getSequenceClassNames(id, intervals.length)}  ${
                donationType === interval && 'checked'
              }`}
              tabIndex={calculateTabIndex()}>
              <I18nCustomFormatter id={PAYMENT_INTERVAL_NAME_MAPPING[interval]} />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );

  const transitions = useTransition(
    gift.funds.filter(({ fund }) => fund.amount || fund.is_dirty),
    {
      keys: ({ fund }) => fund.id,
      from: { opacity: 0, transform: 'translateX(0%)' },
      enter: { opacity: 1, transform: 'translateX(0%)' },
      leave: { opacity: 0, transform: 'translateX(-100%)' },
      config: { tension: 200, friction: 20, duration: 400 }, // Adjust smoothness
    },
  );

  const fundCartContent = (
    <div>
      <div className="fund-cart-item fund-cart-title-bg">
        <h6 className="arc-H200 arc-color-b85 mb0">Gift summary</h6>
      </div>
      <If condition={intervals && intervals.length > 1}>{fundSlabsComponent}</If>
      {transitions((style, { fund }) => (
        <animated.div key={fund.id} style={style}>
          <div className="fund-cart-item arc-d-flex arc-flex-space-between-align arc-flex-vertical-align">
            <p className="arc-H150 arc-color-gray11 arc-font-weight-medium-imp">{fund.name}</p>
            <div className="fund-input arc-d-flex arc-flex-vertical-align">
              <Input
                size="small"
                id={`${fund.name}-input`}
                aria-label={`Enter amount for fund: ${fund.name}`}
                onWheel={(e) => e.target.blur()}
                type="number"
                readOnly={fund.isLocked}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(fund.isLocked && {
                  suffix: (
                    <Tooltip
                      title={
                        <p className="arc-support">
                          You cannot edit this amount. It has been locked by the administrator. For any assistance,
                          please contact {window.collegeEmail}.
                        </p>
                      }>
                      <FontAwesomeIcon icon={faLock} className="arc-color-B25" />
                    </Tooltip>
                  ),
                })}
                tabIndex={calculateTabIndex()}
                value={fund.amount}
                onChange={(e) => updateFundAmount(fund.id, e.target.value, fund.isLocked)}
                prefix={<CurrencyRenderer currencyId={gift.account.currency} />}
                placeholder="Enter Amount"
              />
              <Button
                type="link"
                className="arc-btn-link link-btn arc-color-gray7 ml12"
                onClick={() => deleteFundFromCart(fund.id)}>
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </div>
          </div>
          <div className="fund-divider" />
        </animated.div>
      ))}
    </div>
  );

  const emptyCartContent = (
    <div className="empty-cart-wrapper">
      <FontAwesomeIcon icon={faHandHoldingSeedling} />
      <p className="arc-p arc-color-gray9">Start adding gifts and make an impact today</p>
      <Button type="primary" onClick={goBack}>
        Add gift
      </Button>
    </div>
  );

  return (
    <div className="fund-cart-wrapper">
      <div className="fund-cart-content">
        <Choose>
          <When condition={isCartEmpty}>{emptyCartContent}</When>
          <Otherwise>{fundCartContent}</Otherwise>
        </Choose>
        <If condition={showLimitMenu}>
          <Drawer
            title={null}
            placement="bottom"
            closable={false}
            onClose={toggleLimitMenuVisibility}
            visible={showLimitMenu}
            getContainer={false}
            className="limit-drawer-funds">
            <LimitOptions limitOption={limit} />
          </Drawer>
        </If>
      </div>
      <If condition={!isCartEmpty}>
        <div className="fund-cart-footer">
          <div className="total-row">
            <Row type="flex" justify="space-between">
              <Col>
                <h6 className="arc-color-B85 cs-amount-info-font arc-text-uppercase cs-main-info" role="none">
                  {INTERVAL_NAME_MAPPING[donationType]} TOTAL
                </h6>
              </Col>
              <Col>
                <h6 className="arc-color-B85 cs-amount-info-font" role="none">
                  <CurrencyRenderer currencyId={gift.account.currency} />
                  <Statistic value={parseFloat(total).toFixed(2)} />
                </h6>
              </Col>
            </Row>
            <If condition={!isEmpty(getSupportedLimits()) && shouldShowLimit()}>
              <Row type="flex" justify="space-between" className="limit-info">
                <Col>
                  <h6 className="arc-color-primary cs-amount-info-font cs-main-info arc-text-uppercase pledge-schedule-item">
                    Pledge schedule
                  </h6>
                </Col>
                <Col>
                  <div
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => handleEnterKeyPress(e, toggleLimitMenuVisibility)}
                    onClick={toggleLimitMenuVisibility}>
                    <Choose>
                      <When condition={limit === null || total * limit === 0}>
                        <div className="arc-d-flex arc-flex-v-align-middle arc-color-primary arc-cursor-p">
                          <h6 className="arc-text-underline mr12 cs-amount-info-font arc-color-primary">
                            <CurrencyRenderer currencyId={gift.account.currency} />
                            <Statistic className="arc-text-underline" value={parseFloat(total).toFixed(2)} />
                            {donationType && (donationType === 'month' ? '/mo' : '/yr')}
                          </h6>
                          {!showLimitMenu ? (
                            <FontAwesomeIcon icon={faChevronUp} />
                          ) : (
                            <FontAwesomeIcon icon={faChevronDown} />
                          )}
                        </div>
                      </When>
                      <Otherwise>
                        <div className="arc-d-flex arc-flex-v-align-middle arc-color-primary arc-cursor-p">
                          <h6 className="arc-text-underline mr12 cs-amount-info-font arc-color-primary">
                            <CurrencyRenderer currencyId={gift.account.currency} />
                            <Statistic className="arc-text-underline" value={parseFloat(total * limit).toFixed(2)} />
                            {donationType && (donationType === 'month' ? `(${limit}mo)` : `(${limit}yr)`)}
                          </h6>
                          {!showLimitMenu ? (
                            <FontAwesomeIcon icon={faChevronUp} />
                          ) : (
                            <FontAwesomeIcon icon={faChevronDown} />
                          )}
                        </div>
                      </Otherwise>
                    </Choose>
                  </div>
                </Col>
              </Row>
            </If>
          </div>
          <div className="fund-footer">
            <Button type="primary" onClick={validate} size="large">
              Proceed
            </Button>
          </div>
        </div>
        <If condition={settings.page.is_multiple_funds_attribution_enabled}>
          <Button
            icon={<FontAwesomeIcon icon={faPlusCircle} className="mr8" />}
            shape="round"
            type="primary"
            className="add-another-gift-btn"
            onClick={goBack}>
            Add another gift
          </Button>
        </If>
      </If>
    </div>
  );
}

export default injectIntl(Cart);

import React from 'react';
import { Col, Row } from 'antd';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

function ThemeXHeader(props) {
  return (
    <Row
      className="themex-header-v1"
      type="flex"
      align="middle"
      justify="center"
      style={{ backgroundColor: window.primaryColor }}>
      <Col span={24} className="themex-header-v1-body">
        <If condition={props.backPage}>
          <a href={props.backPage} className="back-link">
            <FontAwesomeIcon icon={faArrowLeft} className="mr8" />
            <span />
          </a>
        </If>
        <Choose>
          <When condition={props.logoRedirectUrl}>
            <a href={props.logoRedirectUrl}>
              <img src={window.headerLogo} className="header-logo" alt={`${window.collegeName} logo`} />
            </a>
          </When>
          <Otherwise>
            <img src={window.headerLogo} className="header-logo" alt={`${window.collegeName} logo`} />
          </Otherwise>
        </Choose>

        {/* <div className="header-name">{`${window.collegeName}`}</div> */}
      </Col>
    </Row>
  );
}

export default ThemeXHeader;

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import { Button, message, Row, Col, Statistic, Input, Menu, Drawer } from 'antd';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { findIndex, isEmpty, find, debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { PaymentLinkConsumer } from 'Src/alumniGiving/context/paymentLink';
import { handleEnterKeyPress } from 'Src/common/components/controller/buttons/buttonController';
import { announceToScreenReader } from 'Src/common/utilities/a11y_util';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { getDonationType, getSequenceClassNames } from 'Src/alumniGiving/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faDonate, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import {
  MODULE_GIFT,
  INTERVAL_NAME_MAPPING,
  PAYMENT_INTERVAL_NAME_MAPPING,
  TYPE_OTHER_FUND,
} from 'Src/alumniGiving/constants';
import { success } from '../../../actionCreators';
import { GiftConsumer } from '../../../context/gift';
import { SettingsConsumer } from '../../../context/settings';
import CurrencySelector from '../currencySelector';
import CurrencyRenderer from '../../../../common/components/currencyRenderer';
import FundCard from './fundCard';
import { OtherFundCard } from './otherFundCard';
import FundCategory from './fundCategory';
import { readableNumber } from '../../../../common/utilities/data_util';

import './style.scss';

function FundSelector(props) {
  const [gift, giftDispatch] = GiftConsumer();
  const [settings] = SettingsConsumer();
  const [paymentLink] = PaymentLinkConsumer();

  const [intervals, setIntervals] = useState([]);
  const [showLimitMenu, setShowLimitMenu] = useState(false);
  const [donationType, setDonationType] = useState(getDonationType(gift));
  const [searchedFunds, setSearchedFunds] = useState([]);
  const [quickSlabs, setQuickSlabs] = useState([]);
  const [showNoSearchResult, setShowNoSearchResult] = useState(false);
  const [limit, setLimit] = useState(gift?.subscription_plan?.limit || null);
  const [currency, selectedCurrency] = useState(settings.page.currency);
  const [fundCategoryParentId, setFundCategoryParentId] = useState(null);
  const [categoryFunds, setCategoryFunds] = useState([]);
  const [showCategoryFunds, setShowCategoryFunds] = useState(false);
  const [categoryBreadcrumbData, setCategoryBreadcrumbData] = useState([]);
  const [showAddAnotherGift, setShowAddAnotherGift] = useState(false);

  const isFundCategoryEnabled = settings.page.is_fund_category_enabled;

  const debouncedAnnounceResults = debounce(
    (resultCount) => announceToScreenReader(`${resultCount || 'No'} result${resultCount !== 1 ? 's' : ''} found`),
    1000,
  );

  const setInitialLimit = () => {
    const { payment_configs: paymentConfigs } = gift.account;
    if (!isEmpty(paymentConfigs)) {
      setLimit(paymentConfigs[0]?.default_limit);
    }
  };

  const campaignHubFunds = useSelector((state) => state.campaignDataReducer.campaignHubFunds);

  // Copy all funds to gift on mount
  useEffect(() => {
    gift.funds = props.funds;
    if (
      !isEmpty(paymentLink.data) &&
      !isEmpty(paymentLink.data.pre_filled_values) &&
      !isEmpty(paymentLink.data.pre_filled_values.splits)
    ) {
      const newFunds = [];
      paymentLink.data.pre_filled_values.splits.forEach((split) => {
        const index = findIndex(gift.funds, (val) => val.fund.id === split.fund.id);
        if (index > -1) {
          gift.funds[index].fund.amount = parseFloat(split.amount);
          gift.funds[index].fund.isLocked = !split.is_amount_editable;
          newFunds.push(gift.funds[index]);
        }
      });
      gift.funds = newFunds;
    }

    if (settings.page.accounts.length > 1) {
      const account = settings.page.accounts.filter((accountL) => accountL.currency === settings.page.currency)[0];
      gift.account = account;
    }
    giftDispatch(success(MODULE_GIFT, gift));
    setInitialLimit();
  }, [props.funds]);

  const getAllIntervals = () => {
    const { payment_configs: paymentConfigs } = gift.account;
    if (!isEmpty(paymentConfigs)) {
      setIntervals(paymentConfigs.map((config) => config.interval));
    }
  };

  useEffect(() => {
    getAllIntervals();
  }, [gift]);

  const total = getAmountSumForFunds();

  function getAmountSumForFunds() {
    let subtotal = 0;
    gift.funds.forEach((orderedFund) => {
      if (orderedFund.fund.amount) {
        subtotal += parseFloat(orderedFund.fund.amount);
      }
    });
    return Math.round((subtotal + Number.EPSILON) * 100) / 100;
  }

  function setGiftCurrency(currencyId) {
    const account = settings.page.accounts.filter((accountL) => accountL.currency === currencyId)[0];
    selectedCurrency(currencyId);
    gift.account = account;
    setDonationType(getDonationType(gift));
    giftDispatch(success(MODULE_GIFT, gift));
  }

  const getIndexOfOtherFund = () => {
    const index = findIndex(gift.funds, (val) => val.fund.type === TYPE_OTHER_FUND);
    return index;
  };

  const getPaymentConfigByInterval = (interval) => {
    const { payment_configs: paymentConfigs } = gift.account || {};
    return find(paymentConfigs, (config) => config.interval === interval);
  };

  function validate() {
    if (!total) {
      message.error('Add donation amount to proceed');
      return;
    }
    const index = getIndexOfOtherFund();
    if (index !== -1) {
      const otherFund = gift.funds[index];
      if (otherFund.fund.amount) {
        if (!otherFund.fund.name) {
          message.error('Add other fund name to proceed');
          return;
        }
      }
    }
    let minimumDonationAmount = null;
    const reqPaymentConfig = getPaymentConfigByInterval(donationType);
    minimumDonationAmount = reqPaymentConfig?.minimum_donation_amount;
    if (minimumDonationAmount && total < minimumDonationAmount) {
      message.error(`Minimum donation amount is ${minimumDonationAmount}`);
      return;
    }
    gift.donation_type = donationType === null ? 'one_time' : 'recurring';
    gift.subscription_plan = {
      interval: donationType,
      limit,
    };
    giftDispatch(success(MODULE_GIFT, gift));
    props.proceedToNextStep();
  }

  const onChangeDonationType = (value) => {
    setDonationType(value);
    const firstPaymentConfig = getPaymentConfigByInterval(value);
    setLimit(firstPaymentConfig.default_limit);
    gift.donation_type = value;

    if (isFundCategoryEnabled) {
      message.info(
        `Gifts in your cart are set to ${props.intl.formatMessage({
          id: PAYMENT_INTERVAL_NAME_MAPPING[value],
        })}`,
      );
    }

    for (let i = 0; i < gift.funds.length; i++) {
      if (!gift.funds[i].fund.isLocked) {
        gift.funds[i].fund.amount = null;
        if (gift.funds[i].fund.type === TYPE_OTHER_FUND) gift.funds[i].fund.name = '';
        gift.funds[i].is_open = false;
      }
    }
    giftDispatch(success(MODULE_GIFT, gift));
  };

  const searchFunds = (fundSearchTerm) => {
    const allFunds = gift.funds;
    const result = [];
    if (fundSearchTerm) {
      // eslint-disable-next-line no-restricted-syntax
      for (const data of allFunds) {
        const { name, description, external_database_lookup_id: externalDatabaseLookupId } = data.fund;
        if (
          name?.toLowerCase().indexOf(fundSearchTerm.toLowerCase()) >= 0 ||
          description?.toLowerCase().indexOf(fundSearchTerm.toLowerCase()) >= 0 ||
          externalDatabaseLookupId?.toLowerCase().indexOf(fundSearchTerm.toLowerCase()) >= 0
        ) {
          result.push({ ...data, hide: false });
        }
      }
      if (result.length) {
        setShowNoSearchResult(false);
      } else {
        setShowNoSearchResult(true);
      }
      setSearchedFunds(result);
    } else {
      setShowNoSearchResult(false);
      setSearchedFunds([]);
    }

    debouncedAnnounceResults(result.length);
  };

  const handleOnSearch = (event) => {
    const { value } = event.target;
    searchFunds(value);
  };

  const getFunds = () => {
    if (searchedFunds.length) return searchedFunds;
    if (showCategoryFunds) return categoryFunds;
    return gift.funds;
  };

  const hasOtherFundWithAmount = () => {
    if (searchedFunds.length === 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const giftL of gift.funds) {
        if (giftL.fund.type === TYPE_OTHER_FUND && giftL.fund.amount) {
          return true;
        }
      }
    }
    return false;
  };

  const getQuickSlabs = () => {
    const paymentConfig = getPaymentConfigByInterval(donationType);
    if (paymentConfig && paymentConfig.quick_select_options) {
      setQuickSlabs(paymentConfig.quick_select_options.split(','));
    }
  };

  useEffect(() => {
    getQuickSlabs();
  }, [donationType]);

  const handleLimitChange = (key) => {
    if (key === 'no_limit') {
      setLimit(null);
    } else {
      setLimit(key);
    }
    setShowLimitMenu(false);
  };

  const getSupportedLimits = () => {
    const reqPaymentConfig = getPaymentConfigByInterval(donationType);
    const { supported_limits: supportedLimits } = reqPaymentConfig || {};
    return supportedLimits;
  };

  const shouldShowLimit = () => {
    const { is_pledge_limit_enabled: isPledgeLimitEnabled } = getPaymentConfigByInterval(donationType) || {
      is_pledge_limit_enabled: false,
    };
    return isPledgeLimitEnabled;
  };

  function handleCategoryCardClick({ id, name }) {
    setFundCategoryParentId(id);
    setCategoryBreadcrumbData([...categoryBreadcrumbData, { id, name }]);
  }

  function handleBackCategoryClick() {
    const updatedCategories = categoryBreadcrumbData.slice(0, -1);

    setFundCategoryParentId(updatedCategories.length ? updatedCategories[updatedCategories.length - 1].id : null);
    setCategoryBreadcrumbData(updatedCategories);
    setShowCategoryFunds(false);
    setCategoryFunds([]);
    setShowAddAnotherGift(false);
  }

  const calculateTabIndex = (isPopup = false) => {
    if (showLimitMenu) {
      return isPopup ? 0 : -1;
    }
    return 0;
  };

  function handleAddAnotherGiftClick() {
    setCategoryBreadcrumbData([]);
    setFundCategoryParentId(null);
    setShowCategoryFunds(false);
    setCategoryFunds([]);
    setShowAddAnotherGift(false);
  }

  // eslint-disable-next-line react/prop-types, no-shadow
  function LimitOptions({ limit }) {
    const supportedLimits = getSupportedLimits();
    const activeLimit = limit?.toString() || 'no_limit';
    return (
      <div>
        <p className="menu-heading arc-H150 arc-color-black">Select schedule</p>
        <Menu
          selectable
          className="limit-options-menu"
          selectedKeys={[activeLimit]}
          onClick={({ key }) => handleLimitChange(key)}>
          <Menu.Item
            onKeyDown={(e) => handleEnterKeyPress(e, () => handleLimitChange('no_limit'))}
            tabIndex={calculateTabIndex(true)}
            key="no_limit">
            <CurrencyRenderer currencyId={gift.account.currency} />
            <Statistic value={parseFloat(total).toFixed(2)} />
            {`/${donationType === 'month' ? 'mo' : 'yr'} (${
              donationType === 'month' ? 'monthly' : 'yearly'
            } supporter)`}
          </Menu.Item>
          <If condition={!isEmpty(supportedLimits)}>
            {supportedLimits.map((limitL) => (
              <Menu.Item
                tabIndex={calculateTabIndex(true)}
                key={limitL}
                onKeyDown={(e) => handleEnterKeyPress(e, () => handleLimitChange(limitL))}>
                <CurrencyRenderer currencyId={gift.account.currency} />
                <Statistic value={parseFloat(total * limitL).toFixed(2)} />
                &nbsp;(
                {limitL}
                {donationType === 'month' ? 'mo' : 'yr'})
              </Menu.Item>
            ))}
          </If>
        </Menu>
      </div>
    );
  }

  const toogleLimitMenuVisibility = () => {
    setShowLimitMenu(!showLimitMenu);
  };

  const getMaxHeightStyleForScrollWrapper = () => {
    let baseHeight = 141;
    if (total) {
      baseHeight = 180;
      if (!isEmpty(getSupportedLimits()) && shouldShowLimit()) {
        baseHeight = 202;
      }
    }
    baseHeight -= 16;

    if (window.pageData.type === 'checkout') {
      baseHeight -= 73;
    }
    if (window.innerWidth <= 500)
      return { maxHeight: `calc(100% - ${baseHeight}px)`, height: `calc(100% - ${baseHeight}px)` };
    return {};
  };

  const searchFundPlaceholder =
    settings?.page?.allow_donation_to_all_funds && (campaignHubFunds === null || campaignHubFunds?.length <= 0)
      ? `Search ${readableNumber(gift.funds.length)} available funds`
      : 'Search funds';

  const fundSlabsComponent = (
    <div className="fund-slabs-header">
      <p
        className={`arc-H200 arc-color-B85 arc-font-weight-medium modal-title ${
          isFundCategoryEnabled ? 'modal-title-left arc-H150' : ''
        }`}>
        <I18nCustomFormatter id="how-would-you-like-to-give" />
      </p>
      <Row gutter={window.innerWidth <= 500 ? 0 : 1} className="payment-type-select">
        {intervals.map((interval, id) => (
          <Col
            key={`${interval}`}
            span={24 / intervals.length}
            onKeyDown={(e) => handleEnterKeyPress(e, () => onChangeDonationType(interval))}
            onClick={() => onChangeDonationType(interval)}>
            <div
              aria-label={`${props.intl.formatMessage({
                id: 'how-would-you-like-to-give',
              })} ${props.intl.formatMessage({ id: PAYMENT_INTERVAL_NAME_MAPPING[interval] })} ${
                donationType === interval ? 'Selected' : ''
              }`}
              role="button"
              className={`select-item ${getSequenceClassNames(id, intervals.length)}  ${
                donationType === interval && 'checked'
              }`}
              tabIndex={calculateTabIndex()}>
              <I18nCustomFormatter id={PAYMENT_INTERVAL_NAME_MAPPING[interval]} />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );

  return (
    <div className="fund-selector-wrapper">
      <div className="limit-menu-drawer-wrapper" style={getMaxHeightStyleForScrollWrapper()}>
        <If condition={settings.page.accounts.length > 1}>
          <CurrencySelector currency={currency} setGiftCurrency={setGiftCurrency} />
        </If>
        <div className="fund-scroll-wrapper">
          <If condition={!isFundCategoryEnabled && intervals && intervals.length > 1}>{fundSlabsComponent}</If>
          <div className="fund-selector-list">
            <If condition={!isFundCategoryEnabled}>
              <h2 className="arc-H200 arc-color-B85 arc-font-weight-medium arc-text-align-c modal-title">
                <I18nCustomFormatter id="select-funds" />
              </h2>
            </If>
            <div className={`fund-search ${settings.page.is_fund_category_enabled ? 'fund-search-no-border' : ''}`}>
              <Input
                allowClear={<CloseCircleOutlined />}
                onChange={handleOnSearch}
                prefix={<SearchOutlined />}
                placeholder={searchFundPlaceholder}
                aria-label={searchFundPlaceholder}
              />
            </div>
            <Choose>
              <When condition={showNoSearchResult}>
                <div className="no-fund">
                  <p className="arc-H150 arc-color-black arc-font-weight-medium">No Results</p>
                  <p className="arc-color-B65">There aren&apos;t any funds with that name. </p>
                </div>
                <If
                  condition={
                    settings.page.allow_donation_to_other_funds &&
                    (campaignHubFunds === null || campaignHubFunds?.length <= 0)
                  }>
                  <div className="fund-list">
                    <OtherFundCard
                      name="Couldn't find the fund you're looking for?"
                      description="If you couldn't find the fund you're looking for, you can add the fund name below"
                      cover_photo={null}
                    />
                  </div>
                </If>
              </When>
              <Otherwise>
                <If condition={isFundCategoryEnabled && !searchedFunds.length}>
                  <div className="fund-category-breadcrumb-wrapper">
                    <Choose>
                      <When condition={categoryBreadcrumbData.length < 1}>
                        <h5 className="arc-H250 mb0">
                          {settings.page.fund_category_header_text || 'Select a category'}
                        </h5>
                      </When>
                      <Otherwise>
                        <Button
                          className="arc-btn-link link-btn fund-breadcrumb-btn"
                          type="link"
                          onClick={handleBackCategoryClick}>
                          <FontAwesomeIcon icon={faArrowLeft} className="mr8" /> Go back
                        </Button>
                      </Otherwise>
                    </Choose>
                  </div>
                </If>
                <If condition={isFundCategoryEnabled && categoryBreadcrumbData.length && showCategoryFunds}>
                  <h5 className="arc-H250 arc-color-gray9 fund-category-breadcrumb-wrapper">
                    {categoryBreadcrumbData[categoryBreadcrumbData.length - 1]?.name}
                  </h5>
                </If>
                <If
                  condition={
                    isFundCategoryEnabled &&
                    intervals &&
                    intervals.length > 1 &&
                    (showCategoryFunds || searchedFunds.length) &&
                    !isEmpty(intervals)
                  }>
                  {fundSlabsComponent}
                </If>
                <If condition={isFundCategoryEnabled && !showCategoryFunds && !searchedFunds.length}>
                  <FundCategory
                    handleCategoryCardClick={handleCategoryCardClick}
                    parentId={fundCategoryParentId}
                    updateFundsForCategory={setCategoryFunds}
                    setShowCategoryFunds={setShowCategoryFunds}
                  />
                </If>
                <If condition={!isFundCategoryEnabled || showCategoryFunds || searchedFunds.length}>
                  <div className="fund-list">
                    {getFunds()
                      .filter((val) => val.fund.type !== TYPE_OTHER_FUND && !val.hide)
                      .map((orderedFund) => (
                        <FundCard
                          tabIndex={calculateTabIndex()}
                          quickSlabs={quickSlabs}
                          key={orderedFund.fund.id}
                          setShowAddAnotherGift={setShowAddAnotherGift}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...orderedFund.fund}
                        />
                      ))}
                    {/* if donation to other fund allowed show, funds with amounts also */}
                    {getFunds()
                      .filter((val) => val.fund.type !== TYPE_OTHER_FUND && val.hide && val.fund.amount)
                      .map((orderedFund) => (
                        <FundCard
                          tabIndex={calculateTabIndex()}
                          quickSlabs={quickSlabs}
                          key={orderedFund.fund.id}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...orderedFund.fund}
                        />
                      ))}
                    <If
                      condition={
                        hasOtherFundWithAmount() ||
                        (settings.page.allow_donation_to_other_funds &&
                          !settings?.page?.allow_donation_to_all_funds &&
                          (campaignHubFunds === null || campaignHubFunds?.length <= 0))
                      }>
                      <OtherFundCard
                        key="other"
                        tabIndex={calculateTabIndex()}
                        name="Couldn't find the fund you're looking for?"
                        description="If you couldn't find the fund you're looking for, you can add the fund name below"
                        cover_photo={null}
                      />
                    </If>
                    <If
                      condition={
                        !showNoSearchResult &&
                        isEmpty(searchedFunds) &&
                        settings?.page?.allow_donation_to_all_funds &&
                        (campaignHubFunds === null || campaignHubFunds?.length <= 0) &&
                        !isFundCategoryEnabled
                      }>
                      <div className="extra-fund-info-wrapper">
                        <FontAwesomeIcon icon={faDonate} />
                        <div className="arc-H150 arc-color-B85 mt15 mb15">
                          {readableNumber(gift.funds.length)} other funds available
                        </div>
                        <div className="arc-H150 arc-color-B55">
                          <I18nCustomFormatter id="search-bar-hint" />
                        </div>
                      </div>
                    </If>
                  </div>
                </If>
              </Otherwise>
            </Choose>
          </div>
        </div>
        <If condition={showLimitMenu}>
          <Drawer
            title={null}
            placement="bottom"
            closable={false}
            onClose={toogleLimitMenuVisibility}
            visible={showLimitMenu}
            getContainer={false}
            className="limit-drawer-funds"
            style={{
              position: 'absolute',
            }}>
            <LimitOptions limit={limit} />
          </Drawer>
        </If>
      </div>
      <If condition={gift.funds.length}>
        <>
          <If condition={total}>
            <div className="total-row">
              <Row type="flex" justify="space-between">
                <Col>
                  <h6 className="arc-color-B85 cs-amount-info-font arc-text-uppercase cs-main-info" role="none">
                    {INTERVAL_NAME_MAPPING[donationType]} TOTAL
                  </h6>
                </Col>
                <Col>
                  <h6 className="arc-color-B85 cs-amount-info-font" role="none">
                    <CurrencyRenderer currencyId={gift.account.currency} />
                    <Statistic value={parseFloat(total).toFixed(2)} />
                  </h6>
                </Col>
              </Row>
              <If condition={!isEmpty(getSupportedLimits()) && shouldShowLimit()}>
                <Row type="flex" justify="space-between" className="limit-info">
                  <Col>
                    <h6 className="arc-color-primary cs-amount-info-font cs-main-info arc-text-uppercase pledge-schedule-item">
                      Pledge schedule
                    </h6>
                  </Col>
                  <Col>
                    <div
                      tabIndex={0}
                      role="button"
                      onKeyDown={(e) => handleEnterKeyPress(e, toogleLimitMenuVisibility)}
                      onClick={toogleLimitMenuVisibility}>
                      <Choose>
                        <When condition={limit === null || total * limit === 0}>
                          <div className="arc-d-flex arc-flex-v-align-middle arc-color-primary arc-cursor-p">
                            <h6 className="arc-text-underline mr12 cs-amount-info-font arc-color-primary">
                              <CurrencyRenderer currencyId={gift.account.currency} />
                              <Statistic className="arc-text-underline" value={parseFloat(total).toFixed(2)} />
                              {donationType && (donationType === 'month' ? '/mo' : '/yr')}
                            </h6>
                            {!showLimitMenu ? (
                              <FontAwesomeIcon icon={faChevronUp} />
                            ) : (
                              <FontAwesomeIcon icon={faChevronDown} />
                            )}
                          </div>
                        </When>
                        <Otherwise>
                          <div className="arc-d-flex arc-flex-v-align-middle arc-color-primary arc-cursor-p">
                            <h6 className="arc-text-underline mr12 cs-amount-info-font arc-color-primary">
                              <CurrencyRenderer currencyId={gift.account.currency} />
                              <Statistic className="arc-text-underline" value={parseFloat(total * limit).toFixed(2)} />
                              {donationType && (donationType === 'month' ? `(${limit}mo)` : `(${limit}yr)`)}
                            </h6>
                            {!showLimitMenu ? (
                              <FontAwesomeIcon icon={faChevronUp} />
                            ) : (
                              <FontAwesomeIcon icon={faChevronDown} />
                            )}
                          </div>
                        </Otherwise>
                      </Choose>
                    </div>
                  </Col>
                </Row>
              </If>
            </div>
          </If>
          <div className="fund-footer">
            <Button type="primary" onClick={() => validate()} size="large">
              Proceed
            </Button>
          </div>
        </>
      </If>
      <If
        condition={isFundCategoryEnabled && showAddAnotherGift && settings.page.is_multiple_funds_attribution_enabled}>
        <Button
          icon={<FontAwesomeIcon icon={faPlusCircle} className="mr8" />}
          shape="round"
          type="primary"
          className="add-another-gift-btn"
          onClick={handleAddAnotherGiftClick}>
          Add another gift
        </Button>
      </If>
    </div>
  );
}

export default injectIntl(FundSelector);

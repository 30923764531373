import React, { useState, Fragment, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import { Row, Col, Button, Input } from 'antd';
import { withRouter } from 'react-router';
import ThemeXModal from 'Src/common/components/themeXModal';
import { INFLUENCER_API_PATH } from 'Src/alumniGiving/endpoints';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import instance from 'Src/common/utilities/axios_util';
import { announceToScreenReader } from 'Src/common/utilities/a11y_util';
import ThemeXShareModal from 'Src/common/components/themeXShareModal';

import '@ant-design/compatible/assets/index.css';
import './style.scss';

const FormItem = Form.Item;

function InfluencerModal({ visible, toggleVisibility, form, openShareModelWithData }) {
  const { getFieldDecorator } = form;
  const [settings] = SettingsConsumer();
  const [saving, isSaving] = useState(false);

  function onResponse(
    { page_share_url: pageShareUrl, dashboard_url: dashboardUrl = null, id, page_share_id: pageShareId },
    values,
  ) {
    if (pageShareUrl) {
      // when user is always influencer
      openShareModelWithData({
        url: pageShareUrl,
        email: values.email,
        name: values.name,
        id,
        dashboardUrl,
        page_share_id: pageShareId,
      });
    }
    isSaving(false);
    toggleVisibility(false);
  }

  function onSubmit(e) {
    e.preventDefault();
    form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        isSaving(true);
        instance
          .get(
            INFLUENCER_API_PATH.replace('{}', settings.page.id).concat(
              'share_url_by_email/?email=',
              encodeURIComponent(value.email),
            ),
            { hideNotification: true },
          )
          .catch(() =>
            // if user is not influencer then make user as influencer
            instance.post(INFLUENCER_API_PATH.replace('{}', settings.page.id), value),
          )
          .then((response) => {
            onResponse(response.data, value);
          })
          .catch(() => {
            isSaving(false);
          });
      }
    });
  }

  function validateFullName(rule, value, callback) {
    value = value && value.trim();
    if (value) {
      // eslint-disable-next-line no-useless-escape
      const regex =
        /^[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*( [a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*)+$/;
      if (!regex.test(value.toLowerCase())) {
        callback('Please enter full name');
      }
    }
    callback();
  }

  return (
    <ThemeXModal
      visible={visible}
      closable
      maskClosable
      keyboard={false}
      handleCancel={() => toggleVisibility(false)}
      modalTitle=""
      Footer={
        <Button
          className="influencer-action-btn"
          type="primary"
          onClick={(e) => onSubmit(e)}
          loading={saving}
          size="large"
          htmlType="submit">
          Share Campaign
        </Button>
      }
      wrapClassName="influencer-details-form">
      <div className="header-image-wrapper">
        {/* decorative image don't need alt */}
        <img alt="" src={`${window.staticPath}img/givingx/giving_influencer_sharing_new.svg`} />
      </div>
      <Form>
        <Row className="avatar-row">
          <Col span={24} className="avatar-col">
            <p className="title arc-H300 arc-color-black">Join the leaderboard</p>
            <p className="description arc-p">You will receive an email with details on how to track your impact</p>
          </Col>
        </Row>
        <p className="arc-support arc-color-gray8 arc-text-align-c">Asterisk (*) Indicates a required field</p>

        <Row className="influencer-row">
          <FormItem label="Full Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input your full name',
                  whitespace: true,
                },
                {
                  validator: validateFullName,
                },
              ],
              validateTrigger: 'onBlur',
            })(<Input placeholder="John doe" aria-required="true" className="share-leaderboard-input" />)}
          </FormItem>
          <FormItem label="Email">
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail',
                  transform: (value) => value && value.trim(),
                },
                {
                  required: true,
                  message: 'Please input your E-mail',
                },
              ],
              validateTrigger: 'onBlur',
            })(<Input placeholder="jdoe@gmail.com" aria-required="true" className="share-leaderboard-input" />)}
          </FormItem>
        </Row>
      </Form>
    </ThemeXModal>
  );
}

const InfluencerModalWithForm = Form.create()(InfluencerModal);

function Influencer({ visible, history, onClose }) {
  const [settings] = SettingsConsumer();
  const [isInfluencerModelOpen, toggleInfluencerModel] = useState(false);
  const [isShareModel, toggleShareModel] = useState(false);
  const [email, setEmail] = useState('');
  const [shareId, setShareId] = useState('');
  const [dashboardUrl, setDashboardUrl] = useState('');
  const [isMount, setIsMount] = useState(false);
  const [publicShareUrl, setPublicShareUrl] = useState('');

  useEffect(() => {
    setIsMount(true);
  }, []);

  useEffect(() => {
    if (isMount) toggleInfluencerModel(visible);
  }, [visible]);

  // eslint-disable-next-line no-shadow
  function openShareModelWithData({ url, email, dashboardUrl, page_share_id: pageShareId }) {
    if (url) {
      const shareUrl = new URL(url);
      shareUrl.searchParams.append('utm_source', 'ref-link');
      shareUrl.searchParams.append('utm_medium', 'direct');
      setPublicShareUrl(shareUrl.href);
    }
    setEmail(email);
    setDashboardUrl(dashboardUrl);
    setShareId(pageShareId);
    toggleInfluencerModel(false);
    toggleShareModel(true);
    announceToScreenReader(`A link to your dashboard has been sent to ${email}`);
  }
  function onCloseL() {
    history.push(`/g/${settings.page.slug}/${shareId ? `?r=${shareId}` : ''}`);
    toggleShareModel(false);
  }

  function onInfluencerModalChange(bool) {
    // when modal is closed
    if (!bool) {
      toggleInfluencerModel(bool);
      // reset the visible props of partner-in-crime
      if (onClose) onClose();
    }
  }

  return (
    <Fragment>
      <If condition={isInfluencerModelOpen}>
        <InfluencerModalWithForm
          visible={isInfluencerModelOpen}
          toggleVisibility={(bool) => onInfluencerModalChange(bool)}
          openShareModelWithData={(data) => openShareModelWithData(data)}
        />
      </If>
      <ThemeXShareModal
        className="influencer-invite-modal"
        visible={isShareModel}
        url={publicShareUrl}
        twitterShareText={`Donate to ${settings.page.name}`}
        maskClosable={false}
        dashboardAltMsg={`A link to your dashboard has been sent to ${email}`}
        title={<span>Invite a friend</span>}
        shareBlockText="YOUR INVITE LINK"
        dashboardUrl={dashboardUrl}
        handleClose={() => onCloseL()}
        description=""
      />
    </Fragment>
  );
}

export default withRouter(Influencer);

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/pro-solid-svg-icons';
import { faClock, faLock, faLeaf } from '@fortawesome/pro-light-svg-icons';
import { Statistic } from 'antd';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import CurrencyRenderer from 'Src/common/components/currencyRenderer';
import { getDateTimeDiff } from 'Src/common/utilities/moment_util';
import moment from 'moment';
import { METRIC_CARD_COLOR_CONFIG } from '../../../../constants';
import MetricCard from '..';
import DateTimeMetric from './dateTimeMetric';

const renderCurrency = (currency) => <CurrencyRenderer currencyId={currency} />;

export function ParticipationChallengeMetricCard({ campaign, isDarkMode = false, isNewLayout = false }) {
  const [settings] = SettingsConsumer();

  const { is_complete: isComplete } = campaign;
  const currencyRenderer = renderCurrency(settings?.page?.currency);

  return (
    <MetricCard
      icon={
        <Choose>
          <When condition={isComplete}>
            <FontAwesomeIcon icon={faTrophy} />
          </When>
          <Otherwise>
            <FontAwesomeIcon icon={faLock} />
          </Otherwise>
        </Choose>
      }
      title={
        isComplete ? (
          'Challenge complete'
        ) : (
          <>
            Unlock gift of&nbsp;
            {currencyRenderer}
            <Statistic value={parseFloat(campaign.criteria?.total_amount)} />
          </>
        )
      }
      subtitle={
        isComplete ? (
          <>
            {currencyRenderer}
            <Statistic value={parseFloat(campaign.criteria?.total_amount)} /> unlocked
          </>
        ) : (
          'on completing the challenge'
        )
      }
      iconBackgroundColor={METRIC_CARD_COLOR_CONFIG[isComplete][isDarkMode].iconBackgroundColor}
      iconColor={METRIC_CARD_COLOR_CONFIG[isComplete][isDarkMode].iconColor}
      isDarkMode={isDarkMode}
      isNewLayout={isNewLayout}
    />
  );
}

export function MatchMultiplierMetricCard({ campaign, isDarkMode = false, isNewLayout = false }) {
  const [settings] = SettingsConsumer();
  const { is_complete: isComplete } = campaign;

  const currencyRenderer = renderCurrency(settings?.page?.currency);

  return (
    <MetricCard
      icon={<FontAwesomeIcon icon={faLeaf} />}
      title={`${parseFloat(campaign.criteria?.match_multiplier) + 1}x your impact`}
      subtitle={
        <>
          with gift match of&nbsp;
          {currencyRenderer}
          <Statistic value={parseFloat(campaign.criteria?.total_amount)} />
        </>
      }
      iconBackgroundColor={METRIC_CARD_COLOR_CONFIG[isComplete][isDarkMode].iconBackgroundColor}
      iconColor={METRIC_CARD_COLOR_CONFIG[isComplete][isDarkMode].iconColor}
      isDarkMode={isDarkMode}
      isNewLayout={isNewLayout}
    />
  );
}

export function TimePeriodMetricCard({ campaign, isDarkMode = false, isNewLayout = false }) {
  const [campaignState, setCampaignState] = useState('');
  const { is_complete: isComplete } = campaign;

  useEffect(() => {
    if (getDateTimeDiff(campaign.criteria?.start_datetime) <= 0) {
      setCampaignState('started');
    } else if (getDateTimeDiff(campaign.criteria?.end_datetime) <= 0) {
      setCampaignState('ended');
    } else {
      setCampaignState('not_started');
    }
  }, []);

  function getTitle() {
    if (campaignState === 'started') {
      return 'Ends in ';
    }
    if (campaignState === 'ended') {
      return 'Ended on';
    }

    return 'Starts in';
  }

  function getSubtitle() {
    if (campaignState === 'started') {
      return (
        <DateTimeMetric
          dateTime={campaign.criteria?.end_datetime}
          callback={() => setCampaignState('ended')}
          theme={isDarkMode ? 'dark' : 'light'}
        />
      );
    }
    if (campaignState === 'ended') {
      return moment(campaign.criteria?.end_datetime).format('MMM DD, YYYY');
    }

    return (
      <DateTimeMetric
        dateTime={campaign.criteria?.start_datetime}
        callback={() => setCampaignState('started')}
        theme={isDarkMode ? 'dark' : 'light'}
      />
    );
  }

  if (!campaignState) {
    return null;
  }

  return (
    <MetricCard
      icon={<FontAwesomeIcon icon={faClock} />}
      title={getTitle()}
      subtitle={getSubtitle()}
      iconBackgroundColor={METRIC_CARD_COLOR_CONFIG[isComplete][isDarkMode].iconBackgroundColor}
      iconColor={METRIC_CARD_COLOR_CONFIG[isComplete][isDarkMode].iconColor}
      isDarkMode={isDarkMode}
      isNewLayout={isNewLayout}
      isDateTimeMetric={true}
    />
  );
}

export function ChallengeCompleteMetricCard({ campaign, isDarkMode = false, isNewLayout = false }) {
  const [settings] = SettingsConsumer();
  const currencyRenderer = renderCurrency(settings?.page?.currency);
  const { is_complete: isComplete } = campaign;

  return (
    <MetricCard
      icon={<FontAwesomeIcon icon={faTrophy} />}
      title="Challenge complete"
      subtitle={
        <>
          with gift match of&nbsp;
          {currencyRenderer}
          <Statistic value={parseFloat(campaign.criteria?.amount)} />
        </>
      }
      iconBackgroundColor={METRIC_CARD_COLOR_CONFIG[isComplete][isDarkMode].iconBackgroundColor}
      iconColor={METRIC_CARD_COLOR_CONFIG[isComplete][isDarkMode].iconColor}
      isDarkMode={isDarkMode}
      isNewLayout={isNewLayout}
    />
  );
}

import React, { Fragment, useEffect, useState } from 'react';
import { Badge, Button, Card } from 'antd';
import { getSrcFromSrcSet } from 'Src/common/utilities/dom_utils';
import { find, sumBy, isEmpty } from 'lodash';
import { getFeaturedImage } from 'Src/alumniGiving/common/utils/helper';
import ProgressiveFadedImage from 'Src/common/components/progressiveFadedImage';

import 'emoji-mart/css/emoji-mart.css';
import './item.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp, faChevronCircleDown } from '@fortawesome/pro-solid-svg-icons';

// we are only supporting 4 reactions for now
// const reactions = ['+1', 'heart', 'pray', 'clap']
// TODO - fix animations on npm fas icons
// Reaction Icons are coming from the parent index.js, if you want to add a reaction, add the icon there, the classname here
const reactions = ['fad fa-grin-beam', 'fas fa-heart', 'fas fa-thumbs-up'];

// we need this as we need to clean up doms and browser looses the reference so we will save the reference

function Item({ message, selectedHonorWall, selectEmoji, reactionIcons }) {
  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    // for a11y we're marking aria-hidden on antd emoji list
    const list = document.querySelectorAll('.ant-scroll-number-only-unit');
    list.forEach((li) => {
      li.setAttribute('aria-hidden', 'true');
    });
  }, []);

  function animateAndselectEmoji(e, id, reaction) {
    selectEmoji(id, reaction);
    if (!isReactionDisabled(reaction)) {
      // this is for confetti animation
      const dom = document.querySelector(`.emoticon-list-${id}`);
      if (dom.classList.contains('confetti-on')) {
        dom.classList.remove('confettis-on');
      }
      dom.classList.add('confettis-on');
      // clean up the class ones animation is done
      setTimeout(() => {
        dom.classList.remove('confettis-on');
      }, 600);
    }
  }

  function isReactionDisabled(reaction) {
    const totalCount = sumBy(message.reactions, (val) => (val.is_self ? 1 : 0));
    if (!totalCount) return false;
    const r = find(message.reactions, (val) => val.kind === reaction);
    if (!isEmpty(r) && r.is_self) {
      return !r.is_self;
    }
    return true;
  }

  function toggleDetails(e) {
    if (!e.currentTarget.parentElement.classList.contains('collapsed')) {
      setShowDetails(true);
      e.currentTarget.closest('.tribute-image').classList.add('collapsed');
    } else {
      setShowDetails(false);
      e.currentTarget.closest('.tribute-image').classList.remove('collapsed');
    }
  }

  function getReactionLabel(reaction) {
    let reactionName;
    if (reaction === 'fad fa-grin-beam') {
      reactionName = 'Smiley';
    } else if (reaction === 'fas fa-heart') {
      reactionName = 'Vote';
    } else {
      reactionName = 'Like';
    }

    const tributeType = message.title.split('_').join(' ');

    return `Add ${reactionName} ${tributeType} ${message.description} ${message.name} ${message.gift_label} #${message.gift_position}`;
  }

  return (
    <Card data-id={message.id} className="honor-wall-card honor-wall" bordered={false}>
      <div className="tribute-image">
        <Choose>
          <When condition={message.cover_image}>
            <ProgressiveFadedImage
              className="gallery-cover-photo"
              path={message.cover_image}
              render={(loading, src, srcSetData) => (
                <Fragment>
                  <img
                    className="outer-image blurred"
                    src={getSrcFromSrcSet(srcSetData.srcSet, src)}
                    style={{
                      opacity: loading ? 0.4 : 1,
                      transition: 'all 0.3s ease-in',
                    }}
                  />
                  <img
                    src={getSrcFromSrcSet(srcSetData.srcSet, src)}
                    style={{
                      transform: loading ? 'scale(0)' : 'scale(1)',
                      opacity: loading ? 0 : 1,
                      transition: 'all 0.3s ease-in',
                    }}
                    className="inner-image"
                  />
                </Fragment>
              )}
            />
          </When>
          <Otherwise>
            <img src={getFeaturedImage()} className="outer-image blurred" />
            <img src={getFeaturedImage()} className="inner-image" />
          </Otherwise>
        </Choose>
        <Choose>
          <When condition={showDetails}>
            <FontAwesomeIcon
              icon={faChevronCircleUp}
              className="up-icon"
              onClick={(e) => toggleDetails(e)}
              tabIndex="0"
              onKeyDown={(e) => {
                if (e.key === 'Enter') toggleDetails(e);
              }}
              role="button"
              aria-expanded="false"
              aria-label="Expand details"
            />
          </When>
          <Otherwise>
            <div className="details-wrapper" style={{ background: `${window.primaryColor}CC` }}>
              <FontAwesomeIcon
                icon={faChevronCircleDown}
                className="down-icon"
                onClick={(e) => toggleDetails(e)}
                tabIndex="0"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') toggleDetails(e);
                }}
                role="button"
                aria-expanded="true"
                aria-label="Collapse details"
              />
              <p className="arc-H200 honor-title">{message.title.split('_').join(' ')}</p>
              <p className="honor-message">{message.description}</p>
              <p className="arc-H200 honor-creator mt8">
                <div>
                  <p className="arc-p title">{message.name}</p>
                </div>
              </p>
              <p className="arc-p arc-support gift-position">
                <span className="gift-label">{message.gift_label}</span> #{message.gift_position}
              </p>
            </div>
          </Otherwise>
        </Choose>
      </div>
      <div className="emoticon-container arc-card-box-shadow">
        <div className={`selected-emoticons emoticon-list-${message.id}`} data-confettis>
          {reactions.map((reaction) => (
            <Button
              onClick={(e) => animateAndselectEmoji(e, message.id, reaction)}
              className="emoticon-item"
              size="small"
              aria-label={getReactionLabel(reaction)}
              disabled={isReactionDisabled(reaction)}>
              <FontAwesomeIcon
                icon={reactionIcons[reaction]}
                className={`${isReactionDisabled(reaction) ? 'emoticon-icon disabled' : 'emoticon-icon'}`}
              />
            </Button>
          ))}
        </div>
        <Badge
          count={message.total_reactions}
          overflowCount={100000}
          showZero
          style={{
            backgroundColor: 'transparent',
            color: '#333',
            fontSize: '14px',
            border: 0,
            boxShadow: 'none',
            fontWeight: 400,
          }}
        />
      </div>
      <div className="useless-div" />
      {selectedHonorWall[message.id] && selectedHonorWall[message.id].map((val) => val)}
    </Card>
  );
}

export default Item;

/* eslint-disable no-useless-escape */
/* eslint-disable complexity */
import { isEmpty, keys, toPairs, isArray, has, groupBy } from 'lodash';
// eslint-disable-next-line import/no-cycle
import instance from 'Src/common/utilities/axios_util';
import { GET_TIMEZONE_LIST_API_PATH } from 'Src/adminGiving/endpoints';
import moment from 'moment-timezone';
import { EMAIL_VALIDATION_URL } from 'Src/common/endpoints';
import { DEFAULT_RESULT_LIMIT, DEFAULT_PAGE, CURRENCIES } from '../constants';
import { getFormattedDate } from './moment_util';
// polyfill for Number.isInteger
Number.isInteger =
  Number.isInteger ||
  function (value) {
    return typeof value === 'number' && Number.isFinite(value) && Math.floor(value) === value;
  };

function dataValidateWrapper(data, field) {
  return !!(
    (!isEmpty(data) || data[field] || data[field] === 0 || typeof data[field] === 'boolean') &&
    data[field] != null &&
    typeof data[field] !== 'undefined'
  );
}

export const getDateAndTime = (objectData, key, format, defaultValue) => {
  const dateFormat = format || 'YYYY-MM-DD HH:mm:ss';
  if (dataValidateWrapper(objectData, key) && moment(objectData[key], dateFormat).isValid()) {
    return moment(objectData[key], dateFormat);
  }
  return defaultValue && moment(defaultValue, dateFormat).isValid() ? moment(defaultValue, dateFormat) : null;
};

// Method to remove the blank attributes from object (supported nested also)
export const removeEmpty = (obj) => {
  toPairs(obj).forEach(([key, val]) => {
    if (!Array.isArray(val)) {
      if (val && typeof val === 'object') {
        // eslint-disable-next-line no-unsafe-negation
        if (!val instanceof moment) {
          removeEmpty(val);
        }
      } else if (val == null || (val === '' && typeof val !== 'boolean')) {
        delete obj[key];
      }
    } else if (!val.length) {
      delete obj[key];
    }
  });
};

export const replaceEmpty = (obj) => {
  toPairs(obj).forEach(([key, val]) => {
    if (!Array.isArray(val)) {
      if (val && typeof val === 'object') replaceEmpty(val);
      else if (val == null || (val === '' && typeof val !== 'boolean')) {
        obj[key] = null;
      }
    }
  });
};

export const mapValueAsIdObject = (value) => (value ? { id: value } : null);

export const mapArrayAsIdObject = (value) => (value && value.length ? value.map((item) => ({ id: item })) : []);

export const getSpecificKeyValuesFromArray = (data, key) => (data ? data.map((item) => item[key]) : []);

export const getKeyValue = (objectData, key, defaultValue) => {
  if (typeof objectData === 'undefined') return defaultValue;
  const objectKeys = typeof key !== 'undefined' ? key.split('__') : [];
  let objectValue = objectData;
  if (!isEmpty(objectData)) {
    objectKeys.forEach((objectKey) => {
      if (dataValidateWrapper(objectValue, objectKey)) {
        objectValue = objectValue[objectKey];
      } else {
        objectValue = '';
      }
    });
    if (objectValue || objectValue === 0 || typeof objectValue === 'boolean') {
      return objectValue;
    }
    return defaultValue || typeof defaultValue === 'boolean' || Number.isInteger(defaultValue) ? defaultValue : '';
  }
  return defaultValue || typeof defaultValue === 'boolean' || Number.isInteger(defaultValue) ? defaultValue : '';
};

export const addOrUpdateArray = (arrayValue, item, isNewItem = false, hasDeleted = false) => {
  if (isNewItem) {
    arrayValue.push(item);
  } else {
    const index = arrayValue.findIndex((i) => i.id === item.id);
    if (index > -1) {
      if (hasDeleted) {
        arrayValue.splice(index, 1);
      } else {
        arrayValue[index] = item;
      }
    }
  }
  return arrayValue;
};

export const updateObjectValues = (oldObject, newObject, isFormData = false) => {
  // eslint-disable-next-line array-callback-return
  keys(oldObject).map((key) => {
    let newValue = null;
    if (Array.isArray(oldObject[key]) && newObject[key] === '') {
      newValue = [];
    } else {
      // eslint-disable-next-line no-nested-ternary
      newValue = newObject[key] || typeof newObject[key] === 'boolean' ? newObject[key] : isFormData ? '' : null;
    }
    oldObject[key] = newValue;
  });
  delete oldObject.id;
  return oldObject;
};

export const getCurrency = (currencyId, currencies) => {
  const currencyObj = currencyId ? currencies.filter((currency) => currency.id === currencyId)[0] : null;
  return currencyObj ? currencyObj.html_entity : null;
};

export const generateUrl = (params) => {
  let apiUrl = `${params.url}?page_size=${params.size ? params.size : DEFAULT_RESULT_LIMIT}&page=${
    params.page ? params.page : DEFAULT_PAGE
  }`;
  if (params.filters) {
    if (apiUrl.indexOf('?') === -1) {
      apiUrl = `${apiUrl}?${params.filters}`;
    } else {
      apiUrl = `${apiUrl}&${params.filters}`;
    }
  }
  if (params.fields) {
    apiUrl += params.fields;
  }
  if (params.sorter) {
    apiUrl += params.sorter;
  }
  if (params.query) {
    apiUrl = `${apiUrl}&search=${params.query}`;
  }
  return apiUrl;
};

export const getFirstAndLastName = (value) => {
  const splitName = value.split(' ');
  return {
    first_name: splitName[0],
    last_name: splitName.length > 1 ? splitName.slice(1).join(' ') : null,
  };
};

export const validatePositiveNumber = (rule, value, callback) => {
  if (value && value <= 0) {
    callback('Value should be greater then zero');
  }
  callback();
};

export const validateNonNegativeNumber = (rule, value, callback) => {
  if (value && value < 0) {
    callback('Value must be a non-negative number.');
  }
  callback();
};

function getObjectLabel(parentLabel, secondaryLabel, index, key) {
  let label = parentLabel.replace(/_/g, ' ');
  label = label.charAt(0).toUpperCase() + label.slice(1);
  if (index > 0) {
    // eslint-disable-next-line radix
    label = `${label} (${parseInt(index) + 1})`;
  }
  // eslint-disable-next-line eqeqeq
  if (parentLabel != key || key === 'name') {
    if (key === 'name') {
      if (secondaryLabel) {
        label = label.concat(' > ', secondaryLabel.replace(/_/g, ' '));
      }
      return label;
    }
    label = label.concat(' > ', key.replace(/_/g, ' '));
  }
  return label;
}

function generateErrorString(errors) {
  let errorString = '';
  // eslint-disable-next-line no-unused-expressions
  errors &&
    // eslint-disable-next-line array-callback-return
    errors.map((error) => {
      errorString += `<span>${error.label}: ${error.detail}</span>`;
    });
  return errorString;
}

function nestedWalk(key, value, eleValue, eleIndex, eleParentLabel, eleClosestLabel) {
  const parentLabel = eleParentLabel || key;
  let secondaryLabel = eleClosestLabel;
  if (typeof value !== 'string') {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const k in value) {
      if (isNaN(key)) {
        if (typeof value[k] === 'string') {
          eleValue.push({
            label: getObjectLabel(parentLabel, secondaryLabel, eleIndex, key),
            index: eleIndex,
            detail: value[k],
          });
        } else {
          secondaryLabel = key;
        }
      }
      nestedWalk(k, value[k], eleValue, !isNaN(key) ? key : eleIndex, parentLabel, secondaryLabel);
    }
  }
  return eleValue;
}

/**
// Walk through error Obj, basically used for 400 Errors message
// please use only for 400 Error message, with standard format which back end
// is set, otherwise it will give unappropriated format.
// format can be like below
// {detail: 'Hey error !'} or {"teachings":[{"department":{"name":["This field may not be blank."]}}]}
//
// It will walk through nested object and return the final HTML output, no need
// of doing anything.
*/
export const walkErrorObj = (obj) => {
  let errorStrings = '';
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in obj) {
    const eleValue = [];
    if (has(obj, key)) {
      if (typeof obj[key] === 'string') {
        errorStrings += `<span>${obj[key]}</span>`;
      } else {
        const errors = nestedWalk(key, obj[key], eleValue, 0);
        errorStrings += generateErrorString(errors);
      }
    }
  }
  return errorStrings;
};

export const subtractValue = (v1, v2) => v1 - v2;

export const divideValue = (nominator, denominator) => {
  if (nominator && denominator) {
    return nominator / denominator;
  }
  return 0;
};

export const getPercentageValue = (nominator, denominator) => divideValue(nominator, denominator) * 100;

export const sumValues = (values) => values.reduce((a, b) => a + b, 0);

export const pluralizeText = (number, word) => (number === 1 ? word : `${word}s`);

export const readableNumber = (number = 0, decimalPosition = 2) => {
  const convertedNumber = parseFloat(parseFloat(number).toFixed(decimalPosition));
  return convertedNumber.toLocaleString();
};

export const readableAbsNumber = (number = 0, decimalPosition = 2) =>
  Math.abs(parseFloat(parseFloat(number).toFixed(decimalPosition))).toLocaleString();

export const decodeJWTToken = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(base64);
};

export const addOrUpdateParam = (url, param, paramVal) => {
  let newAdditionalURL = '';
  let tempArray = url.split('?');
  const baseURL = tempArray[0];
  const additionalURL = tempArray[1];
  let temp = '';
  if (additionalURL) {
    tempArray = additionalURL.split('&');
    for (let i = 0; i < tempArray.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (tempArray[i].split('=')[0] != param) {
        newAdditionalURL += temp + tempArray[i];
        temp = '&';
      }
    }
  }

  const rowsTxt = `${temp}${param}=${paramVal}`;
  return `${baseURL}?${newAdditionalURL}${rowsTxt}`;
};

export const removeURLParameter = (url, parameter) => {
  // prefer to use l.search if you have a location/link object
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = `${encodeURIComponent(parameter)}=`;
    const pars = urlparts[1].split(/[&;]/g);

    // reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    url = `${urlparts[0]}?${pars.join('&')}`;
    return url;
  }
  return url;
};

export const getUrlParam = (name, url) => {
  if (!url) url = window.location.href;
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regexS = `[\\?&]${name}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  return results == null ? null : results[1];
};

export const getNextPageUrl = (url) => {
  const pageNo = getUrlParam(url, 'page');
  const nextUrl = removeURLParameter(url, 'page');
  return `${nextUrl}&page=${pageNo + 1}`;
};

function dec2hex(dec) {
  return `0${dec.toString(16)}`.substr(-2);
}

// generateId :: Integer -> String
export const generateId = (len) => {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};

export const addDisplayOrder = function (values) {
  if (!Array.isArray(values)) {
    return;
  }
  values.forEach((val, index) => {
    val.display_order = index + 1;
  });
};

export const fetchTimeZones = () =>
  instance({
    methods: 'GET',
    url: GET_TIMEZONE_LIST_API_PATH,
  });
export const validateEmailByApi = (data) =>
  instance({
    method: 'POST',
    url: EMAIL_VALIDATION_URL,
    data: { email: data },
    hideNotification: true,
  });
export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isUrlValid = (url) => {
  const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  return re.test(String(url).toLowerCase());
};

export const emailsValidator = (rules, value, callback) => {
  if (value && value.trim() && value.length > 1) {
    const emails = value.split(',');
    emails.forEach((email) => {
      if (email && email.trim() && !validateEmail(email.trim())) {
        callback(`${email} is invalid email`);
      }
    });
    callback();
  }
  callback();
};

export const emailValidator = (rules, value, callback, customMessage = 'Invalid email') => {
  value = value && value.trim();

  if (value) {
    if (!validateEmail(value)) {
      callback(customMessage);
    }
  }
  callback();
};

/**
 * validator with i18n message - wrapper validator with i18n message. pass validator , i18n message , rules,
 *  values, callback
 *  */
export const validatorWrapperWithI118nSupport = (validator, i18Id, rules, values, callback) => {
  let message;
  if (i18Id) {
    message = i18Id;
  }
  return validator(rules, values, callback, message);
};

export const urlValidator = (rules, value, callback, customMessage = 'Invalid url !!') => {
  if (value && value.trim() && value.length > 1) {
    if (!isUrlValid(value)) {
      callback(customMessage);
    }
  }
  callback();
};

export const validateFullName = (rule, value, callback) => {
  value = value && value.trim();
  if (value) {
    const regex =
      /^[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*( [a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*)+$/;
    if (!regex.test(value.toLowerCase())) {
      callback('Please enter full name');
    }
  }
  callback();
};

export const formatter = (currency) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  });

/**
 * Ability to render the list of amounts with currency, in a comma separated format.
 * e.g.,
 * given a list, amounts = [{'total': 20, 'currency': 1}, {'total: 1000, 'currency': 2}]
 * returns a string, $20 + ₹1,000
 */
export const commaSeparatedAmountAndCurrency = (amounts) => {
  if (isEmpty(amounts)) {
    return '-';
  }
  return amounts
    .map((amount) => {
      const currency = CURRENCIES.find((curr) => curr.id === amount.currency);
      return formatter(currency.iso_code).format(amount.total);
    })
    .join(' + ');
};

// this function to find url and wrapper that string (which is url) with anchor tag
export const urlify = (text) => {
  if (text.includes('<a')) return text;
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => `<a href='${url}' target='_blank' rel='noopener noreferrer'>${url}</a>`);
};

export const checkIfUrlContainsImage = (url) => url.match(/\.(jpeg|jpg|gif|png)$/i) != null;

export const checkIfUrlContainsAsset = (url) => url.match(/api\/v[\d]\/assets/i) != null;

/**
 * check if date is rolling date
 */
export const isRollingDate = (date) => date.startsWith('now');

/**
 * get days from rolling date
 */
export const getDaysFromRollingDate = (date) => date.match(/\d+/g)[0];

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

export const convertFilterToQuery = (filters) => {
  let query = '';
  if (!isEmpty(filters)) {
    filters.forEach((filter, index) => {
      query = `${query}${index > 0 && index < filters.length ? '|' : ''}${filter.name}`;
      if (filter.field) {
        query = `__${query}${filter.field}`;
      }
      switch (filter.query.operation) {
        case 'not_contains':
          query = `${query}__in!=`;
          break;
        case 'is':
          query = `${query}=`;
          break;
        case 'gt':
          query = `${query}__gt=`;
          break;
        case 'lt':
          query = `${query}__lt=`;
          break;
        case 'gte':
          query = `${query}__gte=`;
          break;
        case 'lte':
          query = `${query}__lte=`;
          break;
        case 'eq':
          query = `${query}__exact=`;
          break;
        default:
          query = `${query}__in=`;
      }
      const filterValue = filter.query.args.values || filter.query.args.value;
      if (isArray(filterValue)) {
        query = `${query}${filterValue.join(',')}`;
      } else {
        query = `${query}${filterValue}`;
      }
    });
  }
  return query;
};

const getOperator = (value) => {
  console.log(value);
  switch (true) {
    case value.includes('!='):
      if (value.includes('isnull')) {
        return { operation: 'is', operator: '=' };
      }
      return { operation: 'not_contains', operator: '__in!=' };
    case value.includes('__gte'):
      return { operation: 'gte', operator: '__gte=' };
    case value.includes('__exact'):
      return { operation: 'eq', operator: '__exact=' };
    case value.includes('__lte'):
      return { operation: 'lte', operator: '__lte=' };
    case value.includes('__gt'):
      return { operation: 'gt', operator: '__gt=' };
    case value.includes('__lt'):
      return { operation: 'lt', operator: '__lt=' };
    case value.includes('__in='):
      return { operation: 'contains', operator: '__in=' };
    case value.includes('='):
      return { operation: 'is', operator: '=' };
    default:
      return { operation: 'contains', operator: '__in=' };
  }
};

export const convertQueryToFilters = (query, originalFilters) => {
  const filters = [];
  const urlFilters = query.split('|');

  urlFilters.forEach((filter) => {
    const opr = getOperator(filter);
    const path = filter.split(opr.operator)[0];
    const value = filter.split(opr.operator)[1];
    const selectedOriginalFilter = originalFilters.find((val) => val.id === path);
    if (selectedOriginalFilter) {
      filters.push({
        id: path,
        query: {
          operation: opr.operation,
          args: {
            ...(selectedOriginalFilter.type === 'text' &&
              selectedOriginalFilter.options !== null && { values: value.split(',') }),
            ...(selectedOriginalFilter.type === 'text' && selectedOriginalFilter.options === null && { value }),
            ...(selectedOriginalFilter.type !== 'text' && { value }),
          },
        },
        type: selectedOriginalFilter.type,
      });
    }
  });
  return filters;
};

export const humanifyText = (text) => {
  if (!text) {
    return '';
  }

  return text
    ?.split('_')
    ?.map((textL) => textL.charAt(0).toUpperCase() + textL.slice(1))
    ?.join(' ');
};

export const trueTypeOf = (obj) => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

export const parseValue = (value, isHtml = false, key = null) => {
  const type = trueTypeOf(value);
  switch (type) {
    case 'array':
      value = value.join(', ');
      break;
    case 'boolean':
      value = value ? 'True' : 'False';
      break;
    case 'object':
      // eslint-disable-next-line no-case-declarations
      let delimiter = ',';
      if (isHtml) {
        delimiter = '</br>';
      }
      if (key && value[key] !== undefined) {
        value = humanifyText(value[key]);
        break;
      }
      value = Object.entries(value).reduce((str, [p, val]) => `${str}${humanifyText(p)} : ${val} ${delimiter}`, '');
      break;
    case 'string':
      // eslint-disable-next-line no-self-assign
      value = value;
      break;
    default:
      value = '-';
      break;
  }
  return value;
};

export const loadData = async (next, currentData, setLoader, setData, setNext) => {
  if (next) {
    setLoader(true);
    const url = next;
    try {
      const response = await instance.get(url);
      if (response && response.data) {
        // eslint-disable-next-line no-shadow
        const { next, results } = response.data;
        const data = results;
        setData([...currentData, ...data]);
        if (next) setNext(url.replace(`&page_no=${next - 1}`, `&page_no=${next}`));
        else setNext(null);
      }
      setLoader(false);
    } catch (err) {
      console.error(err);
      setNext(false);
      setLoader(false);
    }
  }
};

export const onScroll = (event, fetching, loadDataL) => {
  const { target } = event;
  if (!fetching && target.scrollTop + target.offsetHeight === target.scrollHeight) {
    loadDataL();
  }
};

export const countDecimals = function (value) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1]?.length || 0;
};

export const toFixed = (num, fixed) => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  return num.toString().match(re)[0];
};

export const forceSkipPagination = async (data, func, payload, pageNo) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const response = await func(payload, pageNo);
    if (response && response.data) {
      pageNo++;
      data = [...data, ...response.data.results];
      if (!response.data.next) break;
    }
  }
  return data;
};

export const sortBooleanValue = (array, key = null, order = 'trueFirst') =>
  // eslint-disable-next-line func-names, array-callback-return, consistent-return
  array.sort(function (a, b) {
    let firstValue = a;
    let secondValue = b;
    if (key) {
      firstValue = a[key];
      secondValue = b[key];
    }
    // eslint-disable-next-line no-nested-ternary
    if (order === 'trueFirst') return firstValue === secondValue ? 0 : firstValue ? -1 : 1;
    // eslint-disable-next-line no-nested-ternary
    if (order === 'falseFirst') return firstValue === secondValue ? 0 : firstValue ? 1 : -1;
  });

export const isFullNameValid = (name) => {
  if (!name) return false;
  const regex =
    /^[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*( [a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*)+$/;
  if (!regex.test(name.toLowerCase())) {
    return false;
  }
  return true;
};

export const groupDataByDate = (dataToBeGrouped, groupByKey, dateString, valueKey, nameKey) => {
  let results = [];
  const parsedData = [];
  results = groupBy(dataToBeGrouped, groupByKey);
  // eslint-disable-next-line no-restricted-syntax
  for (const date of keys(results)) {
    const temp = {};
    temp.label = getFormattedDate(date, dateString);
    temp.options = results[date]
      .sort((a, b) => moment(a[groupByKey]) - moment(b[groupByKey]))
      .map((data) => ({ value: data[valueKey], label: data[nameKey] }));
    if (!isEmpty(temp.options)) parsedData.push(temp);
  }
  return parsedData;
};

export const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const toTitle = (text) => text.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (itemL) {
      return num >= itemL.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const compactNumberFormatter = (num) => {
  const intlFormatter = Intl.NumberFormat('en', { notation: 'compact' });
  return intlFormatter.format(num);
};

/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { Tooltip, Col, Row } from 'antd';
import { find } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import FroalaEditorView from 'Src/common/components/froalaEditor/froalaEditorView';
import { handleEnterKeyPress } from 'Src/common/components/controller/buttons/buttonController';
import CampaignGoalBlock from './goal';
import CampaignModal from './modal';
import { fetchHub } from '../../actions/settings';
import { SettingsConsumer } from '../../context/settings';
import {
  ChallengeCompleteMetricCard,
  MatchMultiplierMetricCard,
  ParticipationChallengeMetricCard,
  TimePeriodMetricCard,
} from './challengeMetrics/cards';

import './style.scss';

let foundMatchedCampaign = false;

function TruncatedDescription({ description }) {
  const stripHtml = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const plainText = stripHtml(description);

  const isLongText = plainText.length > 76;

  const getTruncatedContent = () => {
    const truncatedText = plainText.slice(0, 76);
    return `<div>${truncatedText}... <button class="arc-btn link-btn read-more-btn" type="link"><span class="arc-text-underline">read more</span></button></div>`;
  };

  return (
    <Choose>
      <When condition={isLongText}>
        <FroalaEditorView model={getTruncatedContent()} />
      </When>
      <Otherwise>
        <FroalaEditorView model={plainText} />
      </Otherwise>
    </Choose>
  );
}

function CampaignHubs({ hubId, CampaignHubData, isNewLayout = false }) {
  const [settings] = SettingsConsumer();

  const [hubData, setHubData] = useState(CampaignHubData);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  const init = async () => {
    try {
      const response = await fetchHub(settings?.page?.id, hubId);
      if (response && response.data) {
        setHubData(response.data);
      }
    } catch (err) {
      setHubData({});
    }
  };

  const getReqCampaignData = () => find(hubData?.givingx_campaigns, ({ id }) => id === selectedCampaignId) || [];

  const checkForSlug = () => {
    const campaignHubSlug = window.location.pathname.split('/')[3];
    if (campaignHubSlug) {
      return find(hubData?.givingx_campaigns, ({ slug }) => slug === campaignHubSlug);
    }
    return false;
  };

  const shouldOpenCampaignModal = () => {
    const matchedCampaign = checkForSlug();
    if (matchedCampaign && !foundMatchedCampaign) {
      foundMatchedCampaign = true;
      const matchedCampaignElm = document.getElementById(matchedCampaign?.slug);
      if (matchedCampaignElm) matchedCampaignElm.scrollIntoView();
      setTimeout(() => {
        setSelectedCampaignId(matchedCampaign?.id);
      }, [500]);
    }
  };

  useEffect(() => {
    if (hubId) init();
    shouldOpenCampaignModal();
  }, []);

  return (
    <div>
      <div className="section-title">{hubData?.name}</div>
      <Choose>
        <When condition={isNewLayout}>
          <div className="campaign-hub-list-wrapper">
            {hubData?.givingx_campaigns?.map((campaign) => (
              <div
                tabIndex={0}
                className="campaign-hub-card-wrapper-v2"
                key={campaign?.id}
                onKeyDown={(e) => handleEnterKeyPress(e, () => setSelectedCampaignId(campaign?.id))}
                onClick={() => setSelectedCampaignId(campaign?.id)}
                id={campaign?.slug}
                role="button">
                <div>
                  <Choose>
                    <When condition={!campaign?.feature_image}>
                      <div className="campaign-hub-card-img-placeholder">
                        <FontAwesomeIcon icon={faImage} />
                      </div>
                    </When>
                    <Otherwise>
                      <img className="campaign-hub-card-img" alt="" src={campaign?.feature_image} />
                    </Otherwise>
                  </Choose>
                  <Choose>
                    <When condition={campaign?.name?.length > 76}>
                      <Tooltip title={campaign?.name}>
                        <div className="arc-H200 arc-color-primary mt12 mb20 campaign-hub-card-name">
                          {campaign?.name?.slice(0, 76)}...
                        </div>
                      </Tooltip>
                    </When>
                    <Otherwise>
                      <div className="arc-H200 arc-color-primary mt12 mb20">{campaign?.name}</div>
                    </Otherwise>
                  </Choose>
                  <If condition={!campaign?.goal_prefix && campaign.type !== 'type_matching_gifts'}>
                    <TruncatedDescription description={campaign?.description} />
                  </If>
                  <If condition={campaign.type === 'type_participation_challenge' && campaign.criteria?.total_amount}>
                    <div className="mt20">
                      <ParticipationChallengeMetricCard campaign={campaign} isNewLayout={isNewLayout} />
                    </div>
                  </If>
                  <If condition={campaign.type === 'type_matching_gifts'}>
                    <div className="campaign-metrics mt20">
                      <Choose>
                        <When condition={campaign.is_complete}>
                          <ChallengeCompleteMetricCard campaign={campaign} isNewLayout={isNewLayout} />
                        </When>
                        <Otherwise>
                          <MatchMultiplierMetricCard campaign={campaign} isNewLayout={isNewLayout} />
                          <If condition={campaign?.criteria?.start_datetime && campaign?.criteria?.end_datetime}>
                            <TimePeriodMetricCard campaign={campaign} isNewLayout={isNewLayout} />
                          </If>
                        </Otherwise>
                      </Choose>
                    </div>
                  </If>
                </div>
                <If condition={campaign?.goal_prefix || campaign.type === 'type_matching_gifts'}>
                  <div className="campaign-hub-card-goal">
                    <CampaignGoalBlock
                      completionPercentage={campaign?.completion_percentage}
                      goal={campaign?.goal}
                      goalPrefix={campaign.type === 'type_matching_gifts' ? 'amount' : campaign?.goal_prefix}
                      currency={settings?.page?.currency}
                      countDonors={campaign?.count_donors}
                      countGifts={campaign?.count_gifts}
                      amount={campaign?.amount}
                      isMatchingGiftChallenge={campaign.type === 'type_matching_gifts'}
                      isNewLayout
                    />
                  </div>
                </If>
              </div>
            ))}
          </div>
        </When>

        {/* This will be discared later */}
        <Otherwise>
          <Row gutter={[24, 24]}>
            {hubData?.givingx_campaigns?.map((campaign) => (
              <Col
                tabIndex={0}
                className="campaign-hub-col-card-wrapper"
                key={campaign?.id}
                xs={24}
                sm={24}
                md={8}
                onKeyDown={(e) => handleEnterKeyPress(e, () => setSelectedCampaignId(campaign?.id))}
                onClick={() => setSelectedCampaignId(campaign?.id)}
                role="button">
                <div className="campaign-hub-card-wrapper" id={campaign?.slug}>
                  <div>
                    <Choose>
                      <When condition={!campaign?.feature_image}>
                        <div className="campaign-hub-card-img-placeholder">
                          <FontAwesomeIcon icon={faImage} />
                        </div>
                      </When>
                      <Otherwise>
                        <img className="campaign-hub-card-img" alt="" src={campaign?.feature_image} />
                      </Otherwise>
                    </Choose>
                    <div className="arc-H200 arc-color-primary mt12 mb12">{campaign?.name}</div>
                    <div>
                      <FroalaEditorView model={campaign?.description} />
                    </div>
                  </div>
                  <div>
                    <If condition={campaign.type === 'type_participation_challenge' && campaign.criteria?.total_amount}>
                      <div className="mt20">
                        <ParticipationChallengeMetricCard campaign={campaign} isNewLayout={isNewLayout} />
                      </div>
                    </If>
                    <If condition={campaign.type === 'type_matching_gifts'}>
                      <div className="campaign-metrics mt20">
                        <Choose>
                          <When condition={campaign.is_complete}>
                            <ChallengeCompleteMetricCard campaign={campaign} isNewLayout={isNewLayout} />
                          </When>
                          <Otherwise>
                            <MatchMultiplierMetricCard campaign={campaign} />
                            <If condition={campaign?.criteria?.start_datetime && campaign?.criteria?.end_datetime}>
                              <TimePeriodMetricCard campaign={campaign} isNewLayout={isNewLayout} />
                            </If>
                          </Otherwise>
                        </Choose>
                      </div>
                    </If>
                    <If condition={campaign?.goal_prefix || campaign.type === 'type_matching_gifts'}>
                      <div className="campaign-hub-card-goal">
                        <CampaignGoalBlock
                          completionPercentage={campaign?.completion_percentage}
                          goal={campaign?.goal}
                          goalPrefix={campaign.type === 'type_matching_gifts' ? 'amount' : campaign?.goal_prefix}
                          currency={settings?.page?.currency}
                          countDonors={campaign?.count_donors}
                          countGifts={campaign?.count_gifts}
                          amount={campaign?.amount}
                          isMatchingGiftChallenge={campaign.type === 'type_matching_gifts'}
                        />
                      </div>
                    </If>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Otherwise>
      </Choose>

      <If condition={selectedCampaignId}>
        <CampaignModal
          hubId={hubData?.id}
          campaignData={getReqCampaignData()}
          handleClose={() => setSelectedCampaignId(null)}
        />
      </If>
    </div>
  );
}

export default CampaignHubs;

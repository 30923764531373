import React from 'react';
import { getUTCDate } from 'Src/common/utilities/moment_util';
import DateBlock from 'Src/common/components/dateBlock';
import AddToCalendarLink from 'Src/common/components/addToCalendarLink';

function CampaignCalendarBlock({ name, startDateTime, endDateTime, timezone, showTime, venue }) {
  return (
    <div className="calendar-block-wrapper">
      <DateBlock
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        timezone={timezone}
        showTime={showTime}
        shortTimezone={''}
      />
      <AddToCalendarLink
        eventTitle={name}
        startDateTime={getUTCDate(startDateTime, 'YYYYMMDDTHHmm00')}
        endDateTime={getUTCDate(endDateTime, 'YYYYMMDDTHHmm00')}
        location={venue && venue.formatted_address}
      />
    </div>
  );
}

export default CampaignCalendarBlock;

import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import data from './data';

function ConfettiAnimation() {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPlaying(true);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return <Lottie options={defaultOptions} isStopped={!isPlaying} isPaused={false} />;
}

export default ConfettiAnimation;

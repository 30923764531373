/* eslint-disable import/prefer-default-export */
import instance from 'Src/common/utilities/axios_util';

export const triggerWorkflow = (triggerType, parentObjectId) => {
  instance
    .get(`manage/csat/triggers/details?trigger_type=${triggerType}&parent_object_id=${parentObjectId}`, {
      hideNotification: true,
    })
    .then((res) => {
      if (res.data.should_run) {
        window.userpilot.trigger(res.data.template_code);
      }
    });
};

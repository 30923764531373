import React from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/pro-light-svg-icons';

function AddToCalendarLink({ eventTitle, startDateTime, endDateTime, description, location, className, title }) {
  const calendarEvent = {
    title: eventTitle,
    description,
    startTime: startDateTime,
    endTime: endDateTime || startDateTime,
    location,
  };

  return (
    <div className={`giving-add-to-calendar-wrapper ${className}`}>
      <FontAwesomeIcon icon={faCalendarPlus} className="mr8" />
      <AddToCalendar
        event={calendarEvent}
        buttonLabel={title || 'Add to calendar'}
        className="arc-focus-outline"
        buttonTemplate={{ 'calendar-plus-o': 'left' }}
      />
    </div>
  );
}

AddToCalendarLink.propTypes = {
  eventTitle: string.isRequired,
  startDateTime: string.isRequired,
  endDateTime: string,
  description: string,
  location: string,
  className: string,
  title: string,
};

AddToCalendarLink.defaultProps = {
  endDateTime: '',
  description: '',
  location: '',
  className: '',
  title: '',
};

export default AddToCalendarLink;

import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import './style.scss';

const getSeconds = (diff) => Math.trunc(diff) % 60;

const getMintues = (diff) => Math.trunc(diff / 60) % 60;

const getHours = (diff) => Math.trunc(diff / 3600) % 24;

const getDays = (diff) => Math.trunc(diff / 86400);

const makeItAlteastTwoDigits = (value) => (value.toString().length <= 1 ? `0${value.toString()}` : value.toString());

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id = null;
    function tick() {
      if (!savedCallback.current()) {
        clearInterval(id);
      }
    }
    if (delay !== null) {
      id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return () => {};
  }, [delay]);
};

const getDateTimeDiff = (dateTime) => {
  const diff = moment.parseZone(dateTime).unix() - moment.parseZone().unix();
  return diff > 0 ? diff : 0;
};

function DateTimeMetric({ dateTime, callback, theme }) {
  const [diff, setDiff] = useState(getDateTimeDiff(dateTime));
  useInterval(() => {
    if (diff === 0) {
      if (callback) {
        callback();
      }
      return 0;
    }
    setDiff(diff - 1);
    return 1;
  }, 1000);

  return (
    <div className={`campaign-date-time-metric ${theme ? `campaign-date-time-metric-${theme}` : ''}`}>
      {makeItAlteastTwoDigits(getDays(diff))}
      d&nbsp;
      {makeItAlteastTwoDigits(getHours(diff))}
      h&nbsp;
      {makeItAlteastTwoDigits(getMintues(diff))}
      m&nbsp;
      {makeItAlteastTwoDigits(getSeconds(diff))}s
    </div>
  );
}

export default DateTimeMetric;

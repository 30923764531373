import React, { useEffect, useState } from 'react';
import { isEmpty, orderBy } from 'lodash';
import ImageWithFallbackSrc from 'Src/common/components/imageWithFallbackSrc';
import { handleEnterClick } from 'Src/common/utilities/dom_utils';

function FundCategory({ handleCategoryCardClick, parentId, updateFundsForCategory, setShowCategoryFunds }) {
  const [categories, setCategories] = useState([]);
  const parentCategoryName = window.fundCategories?.find((c) => c.id === parentId)?.name;

  useEffect(() => {
    const result = window.fundCategories?.filter((c) => c.parent === parentId);

    if (!isEmpty(result)) {
      const orderedCategories = [...orderBy(result, 'display_order', ['asc'])];
      setCategories(orderedCategories);
    } else {
      const category = window.fundCategories.find((c) => c.id === parentId);
      const funds = category?.funds?.map((f) => ({ fund: f }));
      updateFundsForCategory(funds);
      setShowCategoryFunds(true);
    }
  }, [parentId]);

  return (
    <div className="fund-list categories-list">
      <If condition={parentCategoryName}>
        <h5 className="arc-H250 arc-color-gray9">{parentCategoryName}</h5>
      </If>
      {categories.map((category) => (
        <div
          key={category.id}
          role="button"
          tabIndex={0}
          className="fund-card-wrapper category-card-wrapper fund-card-detail-hover"
          onClick={() => handleCategoryCardClick(category)}
          onKeyDown={(e) => handleEnterClick(e, () => handleCategoryCardClick(category))}>
          <div className="fund-card-detail arc-flex-space-between-align">
            <div className="fund-card-text-info">
              <div className="fund-card-title arc-color-primary arc-H250 arc-font-weight-medium ">
                {category.name || ''}
              </div>
              <If condition={category.description}>
                <div className="fund-card-description arc-p arc-color-B65 mt12">{category.description}</div>
              </If>
            </div>
            <If condition={category.feature_image}>
              <ImageWithFallbackSrc
                alt="fund-image"
                className="fund-img"
                src={category.feature_image}
                width={60}
                height={60}
              />
            </If>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FundCategory;

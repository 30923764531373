import React from 'react';
import { Col, Row, Progress, Statistic, Tooltip } from 'antd';
import { round } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingHeart, faTrophy } from '@fortawesome/pro-solid-svg-icons';
import { faUsers, faGift, faSeedling } from '@fortawesome/pro-light-svg-icons';
import CurrencyRenderer from 'Src/common/components/currencyRenderer';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { SettingsConsumer } from '../../../context/settings';

import './style.scss';

function CampaignGoalBlock() {
  const [settings] = SettingsConsumer();

  function getCompletionPerc() {
    if (settings.page.completion_percentage < 3) {
      return 3;
    }
    return parseFloat(settings.page.completion_percentage);
  }

  return (
    <div className="goal-block-wrapper">
      <Row type="flex" justify="space-between" gutter={32}>
        <Col>
          <div className="arc-p arc-color-B85">
            <Choose>
              <When condition={settings.page.goal_prefix === 'donor'}>
                <FontAwesomeIcon icon={faUsers} />
                <I18nCustomFormatter id="donors" values={{ donors: settings.page.count_donors }} />
              </When>
              <Otherwise>
                <FontAwesomeIcon icon={faGift} className="mr8" />
                <I18nCustomFormatter id="gifts" values={{ gifts: settings.page.count_gifts }} />
              </Otherwise>
            </Choose>
          </div>
        </Col>
        <Col>
          <Tooltip
            title={
              <div className="matched-amount-tooltip arc-d-flex">
                <div className="arc-d-flex arc-flex-direction-column">
                  <p>
                    <FontAwesomeIcon icon={faHandHoldingHeart} className="mr6" />
                    Donated
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faTrophy} className="mr6" />
                    Matched
                  </p>
                </div>
                <div className="arc-d-flex arc-flex-direction-column">
                  <p>
                    <span className="mr8 ml8">:</span>
                    <CurrencyRenderer currencyId={settings.page.currency} />
                    <Statistic
                      value={round(parseFloat(settings.page.amount) - parseFloat(settings.page.matching_amount), 2)}
                      className="mr4"
                    />
                  </p>
                  <p>
                    <span className="mr8 ml8">:</span>
                    <CurrencyRenderer currencyId={settings.page.currency} />
                    <Statistic value={parseFloat(settings.page.matching_amount)} className="mr4" />
                  </p>
                </div>
              </div>
            }>
            <div className="arc-p arc-color-B85">
              <FontAwesomeIcon icon={faSeedling} className="mr8" />
              <CurrencyRenderer currencyId={settings.page.currency} />
              <Statistic value={parseFloat(settings.page.amount)} className="mr4" />
              <I18nCustomFormatter id="amount-raised" />
            </div>
          </Tooltip>
        </Col>
      </Row>
      <div className="goal-progressbar">
        <Progress percent={getCompletionPerc()} />
      </div>
      <div className="arc-color-black goal-completion-percentage mt16 mb2">
        <Statistic value={parseFloat(settings.page.completion_percentage)} />%
      </div>
      <div className="arc-color-black goal-giving-of">
        Reached of
        {'  '}
        <If condition={settings.page.goal_prefix === 'amount'}>
          <CurrencyRenderer currencyId={settings.page.currency} />
        </If>
        <Statistic value={settings.page.goal} />
        &nbsp;
        <If condition={settings.page.goal_prefix === 'gift'}>{settings.page.goal > 1 ? 'gifts ' : 'gift '}</If>
        goal
      </div>
    </div>
  );
}

export default CampaignGoalBlock;

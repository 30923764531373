import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Card, Button, Affix } from 'antd';
import { isEmpty, find, debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faArrowLeft, faArrowRight } from '@fortawesome/pro-duotone-svg-icons';
import AddToCalendarLink from 'Src/common/components/addToCalendarLink';
import InfluenceCard from 'Src/alumniGiving/landingPage/leaderboards/influencerLeaderboard/influenceCard';
import { getUTCDate } from 'Src/common/utilities/moment_util';
import { CalendarBlockText } from 'Src/alumniGiving/constants';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
import CampaignHubs from 'Src/alumniGiving/landingPage/campaignHubs';
import { CAMPAIGN_HUB_SECTION } from 'Src/alumniGiving/constants';
import { handleEnterKeyPress } from 'Src/common/components/controller/buttons/buttonController';
import './style.scss';

function BeforeCampaignStart({ settings, hubs, showDonateBlock, props }) {
  const [campaignHubsList, setCampaignHubsList] = useState([]);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const [scrollArrows, setScrollArrows] = useState({ left: false, right: false });

  const sectionRefs = useRef({});
  const navRef = useRef(null);

  const getCampaignHubs = () => {
    const sections = settings?.page?.sections || [];
    return sections
      .filter((section) => section.section.section_type === CAMPAIGN_HUB_SECTION)
      .sort((a, b) => Number(a.display_order) - Number(b.display_order))
      .map((section) => Number(section.section.section_id));
  };

  const checkScrollButtons = debounce(() => {
    if (navRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = navRef.current;
      setScrollArrows({
        left: scrollLeft > 0,
        right: scrollLeft < scrollWidth - clientWidth - 5,
      });
    }
  }, 300);

  const scrollNav = (direction) => {
    if (navRef.current) {
      navRef.current.scrollBy({
        left: direction * 200,
        behavior: 'smooth',
      });

      checkScrollButtons();
    }
  };

  const ensureNavItemVisible = (sectionKey) => {
    setTimeout(() => {
      if (navRef.current && sectionKey) {
        const navItem = navRef.current.querySelector(`[data-section-key="${sectionKey}"]`);
        if (navItem) {
          const navContainer = navRef.current;
          const itemRect = navItem.getBoundingClientRect();
          const containerRect = navContainer.getBoundingClientRect();

          if (itemRect.left < containerRect.left || itemRect.right > containerRect.right) {
            const scrollLeft = navItem.offsetLeft - containerRect.width / 2 + itemRect.width / 2;
            navContainer.scrollTo({
              left: scrollLeft,
              behavior: 'smooth',
            });
          }
        }
      }
    }, 0);
  };

  function scrollToSection(sectionKey) {
    const sectionRef = sectionRefs.current[sectionKey];

    if (sectionRef && sectionRef.getBoundingClientRect) {
      const elementPosition = sectionRef.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - 90,
        behavior: 'smooth',
      });
      setSelectedNavItem(sectionKey);
      ensureNavItemVisible(sectionKey);
    }
  }

  const renderCampaignHub = (sectionId) => {
    const regHub = find(hubs, (hub) => hub.id === sectionId);

    if (regHub) {
      return (
        <Col span={24} key={sectionId} id={`hub-${sectionId}`}>
          <div
            ref={(el) => {
              sectionRefs.current[`hub-${sectionId}`] = el;
            }}>
            <CampaignHubs CampaignHubData={regHub} isNewLayout />
          </div>
        </Col>
      );
    }
    return null;
  };

  useEffect(() => {
    const campaignHubs = getCampaignHubs();
    setCampaignHubsList(campaignHubs);
  }, []);

  useEffect(() => {
    const shareRef = document.getElementById('join_and_share');
    const descriptionRef = document.getElementById('description');
    const readyRef = document.getElementById('ready');

    sectionRefs.current = {
      ...sectionRefs.current,
      join_and_share: shareRef,
      description: descriptionRef,
      ready: readyRef,
    };
  }, []);

  useEffect(() => {
    checkScrollButtons();
  }, [selectedNavItem, campaignHubsList]);

  function createNavItems() {
    const staticNavItems = [
      { key: 'join_and_share', title: 'Join and Share' },
      { key: 'description', title: 'Description' },
    ];

    const hubNavItems = showDonateBlock(props, settings)
      ? campaignHubsList
          .map((hubId) => {
            const hub = find(hubs, (h) => h.id === hubId);
            if (!hub) return null;

            return (
              <div
                role="button"
                tabIndex={0}
                key={`hub-${hubId}`}
                type="link"
                data-section-key={`hub-${hubId}`}
                onKeyDown={(e) => handleEnterKeyPress(e, () => scrollToSection(`hub-${hubId}`))}
                className={selectedNavItem === `hub-${hubId}` ? 'selected nav-item' : 'nav-item'}
                onClick={() => scrollToSection(`hub-${hubId}`)}>
                {hub.name}
              </div>
            );
          })
          .filter(Boolean)
      : [];

    const staticItems = staticNavItems.map((item) => (
      <div
        key={`nav-${item.key}`}
        type="link"
        tabIndex={0}
        role="button"
        data-section-key={item.key}
        onKeyDown={(e) => handleEnterKeyPress(e, () => scrollToSection(item.key))}
        className={selectedNavItem === item.key ? 'selected arc-H150 nav-item' : 'nav-item arc-p'}
        onClick={() => scrollToSection(item.key)}>
        {item.title}
      </div>
    ));

    return [...staticItems, ...hubNavItems];
  }

  return (
    <Col lg={24} md={24} sm={24} xs={24} className="before-campaign-wrapper">
      <Affix>
        <div className="nav-container">
          {scrollArrows.left && (
            <Button className="scroll-btn left-btn ant-btn link-btn" type="link" onClick={() => scrollNav(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          )}
          <div className="nav-items-wrapper" ref={navRef} onScroll={checkScrollButtons}>
            {createNavItems()}
          </div>
          {scrollArrows.right && (
            <Button className="scroll-btn right-btn ant-btn link-btn" type="link" onClick={() => scrollNav(1)}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          )}
        </div>
      </Affix>

      <Row className="getting-ready-container" type="flex" justify="space-between" gutter={[32, 32]} align="top">
        <Col lg={12} md={12} sm={24} xs={24} className="mark-your-calendar-row" id="join_and_share">
          <div className="secondary-calender-link">
            <div id="share-calender-wrapper">
              <div className="share-calender-heading">
                <FontAwesomeIcon icon={faCalendarPlus} className="share-calender-icon" />
                <p className="arc-color-black">
                  <I18nCustomFormatter id={CalendarBlockText.title} />
                </p>
              </div>
              <div className="share-calender-content">
                <p className="arc-p mb16">
                  <I18nCustomFormatter id={CalendarBlockText.description} />
                </p>
                <AddToCalendarLink
                  className="arc-color-primary mt4"
                  title="Add this to your calendar"
                  eventTitle={settings.page.name}
                  startDateTime={getUTCDate(settings.page.start_datetime, 'YYYYMMDDTHHmm00')}
                  endDateTime={getUTCDate(settings.page.end_datetime, 'YYYYMMDDTHHmm00')}
                  location={settings.page.venue && settings.page.venue.formatted_address}
                />
              </div>
            </div>
          </div>
        </Col>

        <Col lg={12} md={12} sm={24} xs={24} className="countdown-influencer-row">
          <InfluenceCard />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} id="description">
          <Card bordered={false} className="campaign-details-block arc-card-box-shadow arc-card-border-radius fr-view">
            <div
              className="page-description"
              dangerouslySetInnerHTML={{
                __html: settings.page.countdown_description || settings.page.description,
              }}
            />
          </Card>
        </Col>

        <If condition={settings.page.countdown_getting_ready_desc}>
          <Col lg={24} md={24} sm={24} xs={24} id="ready">
            <Card
              bordered={false}
              className="campaign-details-block arc-card-box-shadow arc-card-border-radius fr-view">
              <div
                className="page-description"
                dangerouslySetInnerHTML={{
                  __html: settings.page.countdown_getting_ready_desc,
                }}
              />
            </Card>
          </Col>
        </If>

        <If condition={!isEmpty(hubs) && showDonateBlock(props, settings)}>
          <Row className="campaign-details-block-row p16">{campaignHubsList.map(renderCampaignHub)}</Row>
        </If>
      </Row>
    </Col>
  );
}

export default BeforeCampaignStart;
